import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loader from "./components/common/Loader";
import { useAppDispatch, useAppSelector } from "./hooks/hook";
import { dispatchGetLoggedUser } from "./redux/getLoginUserSlice";
import { dispatchGetAllUsers } from "./redux/AdminRedux/getAllCusomersSlice";
import {
  Signup, VerifyOtp, Login, Dashboard, BookAppoint, Subscription, AccountInfo, Setting, Shops, UpdateSlots, Checkout,
  AllCustomersPage,
  AllSellersPage,
  AllPaymentsPage,
  AllShopsPage, EditShopPage, EditStaffPage,
  BookAppointment, BookAppointmentShop, PaymentSuccess, PaymentFailed, TodaysAppoints, ShopperTodaysAppoints, AddNewCustomer
} from './routes/Routes';
//commit changes 
import RootLayout from "./layouts/RootLayout";

import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import ProtectedShopperRoute from "./routes/ProtectedShopperRoute";

import './i18n';
// import SwitchLanguage from "./SwitchLanguage";
import LanguageToggle from './Language/LanguageToggle.js';
import FileInput from "./pages/FileInput";
import Profile from "./layouts/sidebar/Profile";

//landing pages
import Home from './pages/LandingPage/Home.jsx';
import Privacy from './pages/LandingPage/Privacy.jsx';
import Cancellations from './pages/LandingPage/Cancellations.jsx';
import Terms from './pages/LandingPage/Terms.jsx';
import ReturnPolicy from './pages/LandingPage/ReturnPolicy.jsx';

const App = () => {
  const { loading, success: isAuthUser, userData } = useAppSelector(state => state.getLoggedUser)
  const dispatch = useAppDispatch()
  const [checkingAuth, setCheckingAuth] = useState(true)
  useEffect(() => {
    document.title = 'Book Appointment';
  }, []);

  useEffect(() => {
    dispatch(dispatchGetLoggedUser()).then(() => {
      setCheckingAuth(false)
    })
  }, [dispatch])

  // useEffect(() => {
  //   if (!checkingAuth) {
  //     if (isAuthUser) {
  //       if (userData.name === 'Admin') {
  //         dispatch(dispatchGetAllUsers({ role: "customer" }));
  //         dispatch(dispatchGetAllUsers({ role: "shopper" }));
  //       }
  //     }
  //   }
  // }, [dispatch, isAuthUser, userData, loading]);

  if (checkingAuth || loading) {
    return <Loader />
  }
  const pathname = window.location.pathname
  const isRoute = pathname === '/' || pathname === '/privacy-policy' || pathname === '/cancellations-policy' || pathname === '/terms-and-conditions' ? true : false
  return (
    <>
      {/* <SwitchLanguage /> */}
      {!isRoute ? <LanguageToggle /> : ''}
      <BrowserRouter>
        <Routes>
        </Routes>
      </BrowserRouter>
      <BrowserRouter>
        <Routes>
          <Route path="/"
            element={!isAuthUser ? <Home /> : <Navigate to="/" />}
          />
          <Route path="/terms-and-conditions"
            element={!isAuthUser ? <Terms /> : <Navigate to="/" />}
          />
          <Route path="/privacy-policy"
            element={!isAuthUser ? <Privacy /> : <Navigate to="/" />}
          />
          <Route path="/cancellations-policy"
            element={!isAuthUser ? <Cancellations /> : <Navigate to="/" />}
          />
          <Route path="/zero-return-policy"
            element={!isAuthUser ? <ReturnPolicy /> : <Navigate to="/" />}
          />
          <Route
            path="/input"
            element={<FileInput />}
          />
          <Route
            path="/signup"
            element={!isAuthUser ? <Signup /> : <Navigate to="/dashboard" />}
          />
          <Route
            path="/verify"
            // element={!isAuthUser ?  <VerifyOtp /> : <Navigate to="/dashboard" />}
            element={<VerifyOtp />}

          />
          <Route
            path="/login"
            element={!isAuthUser ? <Login /> : <Navigate to="/dashboard" />}
          />
          {/* <Route path="*" element={<Dashboard/>} /> */}
          <Route path="*" element={isAuthUser ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>

      {isAuthUser &&
        <BrowserRouter>
          <RootLayout>
            <Routes>

              <Route path="*" element={isAuthUser ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
              <Route path="/"
                element={!isAuthUser ? <Home /> : <Navigate to="/dashboard" />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/book-appointment" element={<BookAppoint />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/profile" element={<AccountInfo />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/paymentsuccess" element={<PaymentSuccess />} />
              <Route path="/paymentfailed" element={<PaymentFailed />} />

              {/* Shopper Routes  */}
              <Route
                path="/shopper/dashboard"
                element={
                  <ProtectedShopperRoute>
                    <Dashboard />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/book-appointment"
                element={
                  <ProtectedShopperRoute>
                    <BookAppointmentShop />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/profile"
                element={
                  <ProtectedShopperRoute>
                    <Profile />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/generate-slots"
                element={
                  <ProtectedShopperRoute>
                    <Setting />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/shops"
                element={
                  <ProtectedShopperRoute>
                    <Shops />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/update-slots"
                element={
                  <ProtectedShopperRoute>
                    <UpdateSlots />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/edit/:id"
                element={
                  <ProtectedShopperRoute>
                    <EditShopPage />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/todays"
                element={
                  <ProtectedShopperRoute>
                    <ShopperTodaysAppoints />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/addCustomer"
                element={
                  <ProtectedShopperRoute>
                    <AddNewCustomer />
                  </ProtectedShopperRoute>
                }
              />
              <Route
                path="/shopper/staffEdit/:id"
                element={
                  <ProtectedShopperRoute>
                    <EditStaffPage />
                  </ProtectedShopperRoute>
                }
              />

              {/* Admin Routes  */}
              <Route
                path="/admin/todays"
                element={
                  <ProtectedAdminRoute>
                    <TodaysAppoints />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/addCustomer"
                element={
                  <ProtectedAdminRoute>
                    <AddNewCustomer />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedAdminRoute>
                    <Dashboard />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/generate-slots"
                element={
                  <ProtectedAdminRoute>
                    <Setting />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/all-customers"
                element={
                  <ProtectedAdminRoute>
                    <AllCustomersPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/book-appointment"
                element={
                  <ProtectedAdminRoute>
                    <BookAppointment />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/all-sellers"
                element={
                  <ProtectedAdminRoute>
                    <AllSellersPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/all-payments"
                element={
                  <ProtectedAdminRoute>
                    <AllPaymentsPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/all-shops"
                element={
                  <ProtectedAdminRoute>
                    <AllShopsPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/staffEdit/:id"
                element={
                  <ProtectedAdminRoute>
                    <EditStaffPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin/edit/:id"
                element={
                  <ProtectedAdminRoute>
                    <EditShopPage />
                  </ProtectedAdminRoute>
                }
              />

            </Routes>
          </RootLayout>
        </BrowserRouter>
      }
    </>
  )
}

export default App

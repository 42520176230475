import React, { useState } from 'react';
import './LanguageToggle.css';
import i18next from 'i18next';

const LanguageToggle = () => {
    const [isEnglish, setIsEnglish] = useState(true);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

    const toggleLanguage = () => {
        const selectedLang = isEnglish ? 'hi' : 'en';
        setIsEnglish(!isEnglish);
        setLang(selectedLang);
        localStorage.setItem("lang", selectedLang);
        i18next.changeLanguage(selectedLang)
    };


    return (
        <div className={`float-right mr-5 mt-1 ${isEnglish ? 'toggle-container' : 'toggle-container-hide'}`} onClick={toggleLanguage}>
            <div className={`dialog-button ${isEnglish ? '' : 'disabled'}`}>{isEnglish ? 'English' : 'हिंदी'}</div>
        </div>
    );
};

export default LanguageToggle;

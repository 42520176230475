import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetShopByOwner } from '../../../redux/getShopByOwner';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { BsTrash3 } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { clearError, clearMessage, dispatchDeleteShop } from "../../../redux/AdminRedux/deleteShopSLice"
import { successToast, errorToast } from '../../../helper/toast';
import { FaUser } from "react-icons/fa6";

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const modalCustomStyles = {
  content: {
    top: '10%',
    left: '30%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};
const GetShop = () => {
  const { shopList, loading, error, message: message1 } = useAppSelector(
    (state) => state.shopList
  );
  const dispatch = useAppDispatch();
  const { message, success } = useAppSelector(state => state.deleteShop)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(dispatchGetShopByOwner())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  useEffect(() => {
    if (message && success) {
      dispatch(dispatchGetShopByOwner())
      successToast(message)
      dispatch(clearMessage());
      setEditModalIsOpen(false)
    }
  }, [message, dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className='text-center text-3xl m-10 font-[500]'>{message1}</div>;
  }

  if (!shopList || shopList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }
  const handleEdit = (row) => {
    navigate(`/shopper/edit/${row._id}`, { state: { shop: row } });
  };
  const handleDelete = (row) => {
    setEditModalIsOpen(true)
    setSelectedRow(row._id);
  };
  const deleteShop = () => {
    dispatch(
      dispatchDeleteShop({ shopId: selectedRow })
    )
  };
  const handleStaffsBreak = (row) => {
    navigate(`/shopper/staffEdit/${row._id}`, { state: { shop: row } });
  };
  const columns = [
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row)} className='ml-2' style={{ cursor: "pointer"}}><FaRegEdit size={20} /></button>
          <button onClick={() => handleDelete(row)} className='ml-2' style={{ cursor: "pointer"}}><BsTrash3 size={20} /></button>
          <button onClick={() => handleStaffsBreak(row)} className='ml-2'><FaUser size={20} /></button>
        </>
      ),
    },
    {
      name: 'Shop Name',
      selector: 'shopName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'Shop Timing',
      selector: 'shopTime',
      sortable: true,
      center: true,
      marginTop: '10px',
      cell: row => `${row.startTime} - ${row.endTime}` // Combine both values in one cell
    },
    // {
    //   name: 'Start Time',
    //   selector: 'startTime',
    //   sortable: true,
    //   center: true,
    //   marginTop: '10px',
    // },
    // {
    //   name: 'End Time',
    //   selector: 'endTime',
    //   sortable: true,
    //   marginTop: '10px',
    //   center: true,
    // },
    {
      name: 'Break Time',
      selector: 'breakTime', // You can use any unique identifier for the selector
      sortable: true,
      center: true,
      marginTop: '10px',
      cell: row => `${row.breakStartTime} - ${row.breakEndTime}` // Combine both values in one cell
    },
    {
      name: 'Interval',
      selector: 'interval',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'Staffs',
      selector: 'numStaff',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
  ];

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">My Shops</h1>
        <DataTable
          title="Shop List"
          columns={columns}
          data={Array.isArray(shopList) ? shopList : [shopList]}
          customStyles={customStyles}
          pagination
          noHeader
        />
      </div>

      {editModalIsOpen && selectedRow &&
        <Modal isOpen={editModalIsOpen} style={modalCustomStyles}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h1>Are You Sure You Want to Delete shop?</h1>
            </div>
            <div className="body">
              <p>You won't able to retrieve it later!</p>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button onClick={deleteShop}>Continue</button>
            </div>
          </div>

        </Modal>
      }
    </>
  );
};

export default GetShop;

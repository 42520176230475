import axios from "axios"
import { server } from '../server'

export const bookAppointmentApi = async (appointmentData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/bookAppointmentByShopper`,
      // `${server}/api/user/customer/bookAppointment`,
      appointmentData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const createShopApi = async (shopData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/shopper/createShop`,
      shopData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getShopByOwnerApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/shopper/getShopByOwner`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getCustomerByShopper = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/shopper/getCustomers`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getBookingsByOwnerAPi = async (filters) => {
  try {
    const { data } = await axios.post(`${server}/api/user/shopper/getBookingsByOwner`, filters, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getBookingsByCustAPi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/customer/getBookingsByCust`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getSCurrentAppointmentApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/shopper/getCBookingsByShopper`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const deleteAppoitmentApi = async (appointmentData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/shopper/deleteAppointment`,
      appointmentData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}
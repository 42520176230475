import { createSlice } from "@reduxjs/toolkit"
import { deleteAppoitmentApi } from "../api/appointmentApis"

const initialState = {}

export const deleteAppointmentShopper = createSlice({
  name: "deleteAppointmentShopper",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        delShopAppointData: action.payload.delShopAppointData
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = deleteAppointmentShopper.actions
export default deleteAppointmentShopper.reducer
export { clearError, clearMessage }

export const dispatchdeleteAppointmentByShopper = appointmentData => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await deleteAppoitmentApi(
      // JSON.parse(appointmentData),
      appointmentData
    )
    dispatch(setSuccess(response))
  } catch (error) {
    dispatch(setFailed(error))
  }
}


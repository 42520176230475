import axios from "axios"
import { server } from '../server'
export const sendOtpApi = async (userData) => {
  try {
    const { phoneNumber, email } = userData
    const { data } = await axios.post(
      `${server}/api/user/register/sendotp?phoneNumber=${phoneNumber}`,
      // {
      //   email,
      // },
      userData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}
// {
//   withCredentials: true, // Include cookies in the request
// }

export const verifyAndRegisterApi = async (userData, verificationCode) => {
  try {
    const { phoneNumber } = userData
    const { data } = await axios.post(
      `${server}/api/user/verify/register?phoneNumber=${phoneNumber}&verificationCode=${verificationCode}`,
      userData,
      {withCredentials: true}
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const resentOtpApi = async userData => {
  try {
    const { phoneNumber } = userData
    const { data } = await axios.post(
      `${server}/api/user/resentotp?phoneNumber=${phoneNumber}`,
      userData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const userLoginApi = async userData => {
  try {
    const { data } = await axios.post(`${server}/api/user/login`, userData, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getLoggedUserApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/getloggeduser`,{withCredentials: true})
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const logOutUserApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/logout`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const editUserInfoApi = async (userData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/update-user-info`,
        userData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getSubscriptionsApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/getSubscriptions`,{ withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

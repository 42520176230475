import React from 'react'

const Privacy = () => {
  return (
    <div class="bg-gray-100">
    <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Hairbook Refund Policy</h1>

        <p class="mb-4">
            At Hairbook, we are committed to providing high-quality services to our valued 
            customers. To maintain this standard and offer competitive pricing, we have 
            established the following refund policy:
        </p>

        <h2 class="text-2xl font-bold mb-2">1. Zero Refund Policy:</h2>


        <ul class="list-disc list-inside mb-4">
            <li>All sales are final. We do not offer refunds, returns, or exchanges for services purchased from Hairbook.</li>
        </ul>
    </div>
</div>
  )
}

export default Privacy

import { createSlice } from "@reduxjs/toolkit"
import { userLoginApi, verifyAndRegisterApi } from "../api/userApis"

const initialState = {}

export const verifyAndRegisterSlice = createSlice({
  name: "OTPVerification",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        userData: action.payload.userData
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = verifyAndRegisterSlice.actions
export default verifyAndRegisterSlice.reducer
export { clearError, clearMessage }

export const dispatchVerifyAnRegister = verificationCode => async dispatch => {
  try {
    const userData = localStorage.getItem("userRegisterData")
    dispatch(setLoading())
    const response = await verifyAndRegisterApi(
      JSON.parse(userData),
      verificationCode
    )
    dispatch(setSuccess(response))
    localStorage.removeItem("userRegisterData")
  } catch (error) {
    dispatch(setFailed(error))
  }
}

export const dispatchLoginUser = userData => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await userLoginApi(userData)
    dispatch(setSuccess(response))
  } catch (error) {
    dispatch(setFailed(error))
  }
}

import React from 'react'
import Appointment from '../components/Checkout/Checkout.jsx'

const Checkout = () => {

  return (
    <div>
      <Appointment/>
    </div>
  )
}

export default Checkout;
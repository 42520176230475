import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, CircularProgress, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataTable from 'react-data-table-component';
import axios from "axios";
import { server } from "../../server";
import Modal from 'react-modal';
import "./modal.css";
import AlarmClock from '../common/clock/AlarmClock';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { errorToast, successToast } from "../../helper/toast";
import { useAppSelector } from "../../hooks/hook"

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#8080808c',
  color: theme.palette.primary.contrastText,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const EditStaffBreak = () => {
  const location = useLocation();
  const { shop } = location.state;
  const staffList = shop.staffDetails;
  const [expanded, setExpanded] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [breakStartTime, setBreakStartTime] = useState('');
  const [breakEndTime, setBreakEndTime] = useState('');
  const [breakDate, setBreakDate] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const { userData } = useAppSelector(state => state.getLoggedUser)
  var api = userData.role==='admin' ? `${server}/api/user/admin/addStaffData` : `${server}/api/user/shopper/addStaffData`;
  var endpoint = userData.role==='admin' ? `${server}/api/user/admin` : `${server}/api/user/shopper`;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AddNew = (staff) => {
    setSelectedStaff(staff);
    setModalIsOpen(true);
  };

  const getAllStaffDetails = async () => {
    try {
      const { data } = await axios.get(
        `${endpoint}/getStaffData?shopId=${shop._id}`,
        { withCredentials: true }
      );
      console.log("API Response Data: ", data.list); // Log API response data
      setStaffData(data.list);
    } catch (error) {
      console.error("Failed to fetch staff details:", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };
  
  useEffect(() => {
    getAllStaffDetails();

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [shop._id]);

  useEffect(() => {
    console.log("staffData: ", staffData); // Log updated staffData
  }, [staffData]);

  const columns = [
    { name: 'Staff Name', selector: row => row.staffName, sortable: true },
    { name: 'Break Start Time', selector: row => row.breakStartTime, sortable: true },
    { name: 'Break End Time', selector: row => row.breakEndTime, sortable: true },
    { name: 'Break Date', selector: row => row.breakDate, sortable: true },
  ];
  const modalCustomStyles = isMobileView ? {
    content: {
      top: '10%',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
    },
  }
: {
    content: {
      top: '10%',
      left: '35%',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
    },
  };

  const addStaffbreak = async() => {
   try {
    if (!breakDate || !breakEndTime || !breakStartTime) {
      errorToast("All Fields required 🙄")
    } else {
      const { data } = await axios.post(
        `${endpoint}/addStaffData`,
        { breakStartTime, breakEndTime, breakDate, staffId: selectedStaff._id, staffName: selectedStaff.name, shopId: shop._id },
        { withCredentials: true }
      )
      if(data.success) {
        console.log("true")
        successToast("Break details added successfully")
        getAllStaffDetails();
        setModalIsOpen(false);
      } else {
        errorToast("Error while adding break details")
      }
    }
   } catch (error) {
      if(error.response.data.error)
        errorToast(error.response.data.message)
      else 
        errorToast("Error while adding break details")
   }
  };
  const handleDateChange = (date) => {
    if (date) {
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      console.log("Selected date (UTC):", utcDate);
      setBreakDate(utcDate);
    } else {
      setBreakDate(null);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container mx-auto p-4">
        <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%]">
          <h5 className="text-[30px] font-Poppins text-center">Manage Staff Details</h5>
          <Box mt={4}>
            {staffList.map((staff, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
                sx={{ mb: 2 }}
              >
                <CustomAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography>{staff.name}</Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <DataTable
                      title={<button onClick={() => AddNew(staff)} className="link-btn">
                        Add New
                      </button>}
                      columns={columns}
                      data={staffData.filter(item => item.staffId === staff._id)}
                      pagination
                      highlightOnHover
                      noDataComponent={<Typography>No records to display</Typography>}
                    />
                  )}
                </CustomAccordionDetails>
              </Accordion>
            ))}
          </Box>
        </div>
      </div>
      
      {modalIsOpen &&
        <Modal isOpen={modalIsOpen} style={modalCustomStyles}>
          <div className="modalContainer" style={{ height: '500px' }}>
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModalIsOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h1>Add Break Hours</h1>
            </div>
            <div className='m-auto'>
              <div className="w-[100%] m-10">
                <label className="pb-2">
                  Start Time of break
                </label>
                <AlarmClock
                  selectedTime={breakStartTime} setSelectedTime={setBreakStartTime} className={'mt-3 block w-full'} />
              </div>
              <div className="w-[100%] m-10">
                <label className="pb-2">
                  End Time of break
                </label>
                <AlarmClock
                  selectedTime={breakEndTime} setSelectedTime={setBreakEndTime} className={'mt-3 block w-full'} />
              </div>
              <div className="w-[100%] m-10">
                <DatePicker
                  selected={breakDate}
                  onChange={handleDateChange}
                  selectsStart
                  placeholderText="Break Date"
                  className="p-2 border w-40"
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setModalIsOpen(false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button onClick={addStaffbreak}>Continue</button>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default EditStaffBreak;

import { createSlice } from "@reduxjs/toolkit"
import { getUsersApi } from "../../api/adminApis"

const initialState = {}

const getAllUsers = createSlice({
  name: "getAllUsers",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        customerList: action.payload.customersList,
        shopperList: action.payload.shoppersList,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getAllUsers.actions
export default getAllUsers.reducer
export { clearError, clearMessage }

export const dispatchGetAllUsers = (role) => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getUsersApi(role.role)
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}

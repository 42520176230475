import React from 'react'
import Appointment from '../components/Appointment/Appointment'
import ShopperAppoint from '../components/Appointment/ShopperAppoint'
import { useAppSelector } from '../hooks/hook'
const BookAppoint = () => {
  const { userData } = useAppSelector((state) => state.getLoggedUser)
  return (
    <>
      {userData && userData?.role === 'shopper' &&
        <div className="mx-auto w-[100%]">
          <ShopperAppoint />
        </div>
      }
      {userData && userData?.role === 'customer' &&
        <div className="mx-auto w-[100%]">
          <Appointment />
        </div>
      }
    </>
  )
}
// getShopByOwner

export default BookAppoint;
import { createSlice } from "@reduxjs/toolkit"
import { getBookingsByOwnerAPi } from "../api/appointmentApis"

const initialState = {}

const getBookingByOwner = createSlice({
  name: "getBookingByOwner",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        bookingList: action.payload.bookingList,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getBookingByOwner.actions
export default getBookingByOwner.reducer
export { clearError, clearMessage }

export const dispatchGetBookingsByOwner = (filters) => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getBookingsByOwnerAPi(filters)
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}

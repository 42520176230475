import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const UpdateSlots = () => {
    const [isActive, setIsActive] = useState(false);
    const [selected, setIsSelected] = useState("Choose one");

    const handleEdit = (row) => {
        // Handle edit action
    };

    const handleDelete = (row) => {
        // Handle delete action
    };

    return (
        <div className="dropdown">
            <div onClick={() => { setIsActive(!isActive); }} className="dropdown-btn">
                {selected}
                <span className="chevron-icon">
                    {isActive ? <BiChevronUp /> : <BiChevronDown />}
                </span>
            </div>
            <div className="dropdown-content" style={{ display: isActive ? "block" : "none" }}>
                <div onClick={(e) => { setIsSelected(e.target.textContent); setIsActive(!isActive); handleEdit(e);}} className="item">
                    Edit
                </div>
                <div
                    className="item"
                    onClick={(e) => {setIsSelected(e.target.textContent); setIsActive(!isActive); handleDelete(e);}}>
                    Delete
                </div>
            </div>
        </div>
    );
}

export default UpdateSlots;

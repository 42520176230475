import React from 'react'
import { useAppSelector } from '../../hooks/hook'
import { NavLink } from "react-router-dom";
// import { FaShop, FaHouseUser, FaRegUser, FaUser, FaCreditCard, FaCalendar, FaAppStore } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { AiOutlineSetting, AiOutlineShop, AiOutlineCalendar, AiOutlineAppstore } from "react-icons/ai";

const ShopperSideBar = () => {
    const { userData } = useAppSelector((state) => state.getLoggedUser)
    const { t } = useTranslation()
    return (
        (userData && userData?.role === 'shopper' &&
            <>
                <li>
                    <NavLink to={"shopper/todays"} className="link">
                        <AiOutlineAppstore size={23} className="min-w-max" />
                        Today's Appointments
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"shopper/dashboard"} className="link">
                        <AiOutlineAppstore size={23} className="min-w-max" />
                        {/* {t(`dashboard`)} */}
                        All Appointments
                    </NavLink>
                </li>

                <li>
                    <NavLink to={"shopper/book-appointment"} className="link">
                        <AiOutlineCalendar size={23} className="min-w-max" />
                        Book New Appoint
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"shopper/generate-slots"} className="link">
                        <AiOutlineSetting size={23} className="min-w-max" />
                        Setting
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"shopper/shops"} className="link">
                        <AiOutlineShop size={23} className="min-w-max" />
                        Shops
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to={"shopper/update-slots"} className="link">
                        <AiOutlineSetting size={23} className="min-w-max" />
                        Update Slots
                    </NavLink>
                </li> */}
            </>
        )
    )
}

export default ShopperSideBar

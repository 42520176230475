import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetAllUsers } from '../../redux/AdminRedux/getAllCusomersSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import Switch from 'react-switch';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { server } from '../../server';
import { errorToast, successToast } from '../../helper/toast';
import { ToastContainer } from 'react-toastify';
const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = (handleToggleChange) => [
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    marginTop: '10px',
    center: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Phone Number',
    selector: 'phoneNumber',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Role',
    selector: 'role',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Grant/Not',
    selector: 'isApproved',
    // sortable: true,
    center: true,
    marginTop: '10px',
    cell: row => (
      // <Switch
      //   checked={row.isApproved}
      //   onChange={() => handleToggleChange(row)}
      // />
      <Switch
        checked={row.isApproved}
        onChange={() => handleToggleChange(row)}
        onColor="#86d3ff"
        onHandleColor="#2066A9"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={40}
      // className="react-switch"
      // id="material-switch"
      />

    ),

  },
];
const AllSeller = () => {
  const { shopperList, loading, error, message, success } = useAppSelector((state) => state.getAllUsers);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleToggleChange = (row) => {
    const toggleMessage = row.isApproved ?
      'This shopper will be blocked. Proceed?' :
      'This shopper will be eligible to take appointments. Proceed?';
    const btn = row.isApproved ? 'Block' : 'Approve'

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div class="react-confirm-alert-overlay overlay-custom-class-name">
              <div class="react-confirm-alert">
                <div class="react-confirm-alert-body">
                  <h1 className='mb-2'>{btn} Shopper</h1>
                  <p>{toggleMessage}</p>
                  <div class="react-confirm-alert-button-group">
                    <button onClick={onClose} className="alert__btn alert__btn--no">No</button>
                    <button
                      onClick={async () => {
                        const updatedRow = { ...row, isApproved: !row.isApproved }; // Create a copy of row with isApproved toggled
                        await axios.post(`${server}/api/user/admin/approveShopper`, updatedRow, { withCredentials: true })
                          .then((res) => {
                            successToast(res.data.message);
                            dispatch(dispatchGetAllUsers({ role: "shopper" }))
                            onClose();
                          })
                          .catch(err => {
                            errorToast(err.message)
                          });
                      }}
                      className="alert__btn alert__btn--yes"
                    >
                      Yes, {btn}  shopper!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };
  useEffect(() => {
    dispatch(dispatchGetAllUsers({ role: "shopper" }))
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!shopperList || shopperList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }
  return (
    <>
      <ToastContainer />
      <div className="container mx-auto p-4">
        <div className='flex items-center justify-between	'>
          <h1 className="text-2xl mb-4 text-[#333]">All Shoppers  </h1>
          {/* <Link to="/users/new" className="link-btn">
              Add New
            </Link> */}
        </div>
        <DataTable
          title="Shop List"
          columns={columns(handleToggleChange)}
          data={Array.isArray(shopperList) ? shopperList : [shopperList]}
          customStyles={customStyles}
          pagination
          noHeader
        />
      </div>
    </>
  );
};

export default AllSeller;



import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import { FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa6";

function Footer() {
    useEffect(() => {
        const scrollReveal = ScrollReveal({
            origin: 'top',
            distance: '0px',
            duration: 700,
            reset: true
        });

        scrollReveal.reveal(
            `footer .brand, footer .social`,
            { interval: 100 }
        );
    }, []);

    return (
        <footer className="section">
            <div className="container grid">
                <div className="brand">
                    <a className="logo logo-alt" href="#home">
                        hair<span>book</span>.
                    </a>
                    <p>&#169; 2024 HairBook.</p>
                    <p>All rights reserved.</p>
                </div>
                <div className='links'>
                    <div className="brand">
                        <h2>Links</h2>
                        <ul>
                            <li>
                                <a href="/zero-return-policy" target="_blank" rel="noopener noreferrer">
                                    Zero return Policy
                                </a>
                            </li>
                            <li>
                                <a href="/cancellations-policy" target="_blank" rel="noopener noreferrer">
                                    Cancellations Policy
                                </a>
                            </li>
                            <li>
                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                    Terms and Conditions
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='address'>
                    <div className="brand">
                        <h2>Main Office</h2>
                        <p>
                            <span>168, Swastik Township,</span><br />
                            <span>Gate No - 4,</span><br />
                            <span>Navagam, Surat</span><br />
                            <span>Gujarat, 394210</span>
                        </p>

                    </div>
                </div>
                <div className="social grid">

                    <a href="https://www.instagram.com/_hairbook/" target="_blank" rel="noopener noreferrer">
                        {/* <i className="icon-instagram"></i> */}
                        <FaInstagram size={25} color='white' />

                    </a>
                    <a href="https://www.facebook.com/_hairbook" target="_blank" rel="noopener noreferrer">
                        {/* <i className="icon-facebook"></i> */}
                        <FaFacebookF size={25} color='white' />
                    </a>
                    <a href="https://www.youtube.com/_hairbook" target="_blank" rel="noopener noreferrer">
                        {/* <i className="icon-youtube"></i> */}
                        <FaYoutube size={25} color='white' />
                    </a>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
// 168, Swastik Township, Gate No - 4, navagam, Surat GUJARAT 394210

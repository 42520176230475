import React from 'react'
import  PaymentsReceipt from '../components/Admin/TodaysAppoints.jsx'

const TodaysAppoints = () => {
  return (
    <PaymentsReceipt/>
  )
}

export default TodaysAppoints

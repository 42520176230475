import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dispatchGetBookingsByOwner } from '../../redux/getBookingsByOwner';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { dispatchdeleteAppointmentByShopper } from '../../redux/deleteAppointmentShopper';
import { BsTrash3 } from "react-icons/bs";
import Modal from 'react-modal';
import { clearError, clearMessage, dispatchdeleteAppointment } from "../../redux/AdminRedux/deleteAppoitmentSLice"
import { successToast, errorToast } from '../../helper/toast';

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const modalCustomStyles = {
  content: {
    top: '10%',
    left: '30%',
    right: 'auto',
    bottom: 'auto',
    border: 'none'
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};
const Shopper = () => {
  const { bookingList, loading, error, message: bookingMessage } = useAppSelector(
    (state) => state.bookingList
  );
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { message, success } = useAppSelector(state => state.delShopAppointData)

  const handleDelete = (row) => {
    setEditModalIsOpen(true)
    setSelectedRow(row._id);
  };
  const deleteAppointment = () => {
    dispatch(
      dispatchdeleteAppointmentByShopper({ appointmentId: selectedRow })
    )
  };
  useEffect(() => {
    dispatch(dispatchGetBookingsByOwner())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);
  useEffect(() => {
    if (message && success) {
      dispatch(dispatchGetBookingsByOwner())
      dispatch(clearMessage());
      setEditModalIsOpen(false)
      successToast(message)
    }
    if(error && bookingMessage) {
      console.log("error: ", error, bookingMessage)
    }
  }, [message, dispatch]);
  const handleFilter = () => {
    const filters = {};
    if (startDate) filters.startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    if (endDate) filters.endDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    filters.searchString = searchString;

    dispatch(dispatchGetBookingsByOwner(filters));
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchString('');
    dispatch(dispatchGetBookingsByOwner());
  };
  const columns = [
    {
      name: 'Actions',
      center: true,
      cell: (row) => (
        <>
          <button onClick={() => handleDelete(row)} style={{ cursor: "pointer"}}><BsTrash3 size={20} /></button>
        </>
      ),
    },
    {
      name: 'Customer',
      selector: 'customerName',
      sortable: true,
      marginTop: '10px',
      center: true,
    },
    {
      name: 'Appoint. Date',
      selector: 'appointmentDate',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    // {
    //   name: 'bookedDate',
    //   selector: 'bookedDate',
    //   sortable: true,
    //   center: true,
    //   marginTop: '10px',
    // },
    {
      name: 'Owner',
      selector: 'ownerName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'Shop',
      selector: 'shopName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'Staff Name',
      selector: 'staffName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'Slot',
      selector: 'slot',
      sortable: true,
      center: true,
      marginTop: '10px',
      cell: row => `${row.slot[0].startTime} - ${row.slot[0].endTime}`,
    },
    {
      name: 'Booked By',
      selector: 'bookedBy',
      sortable: true,
      center: true,
      marginTop: '10px',
      cell: row => row.bookedBy ? row.bookedBy : 'customer',
    },
  ];
  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">My Bookings</h1>
        <div className="flex flex-wrap items-center mb-4">
          <div className="mr-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="p-2 border w-40"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="mr-2">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="p-2 border w-40"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="mr-2">
            <input
              type="text"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              placeholder="Search"
              className="p-2 border w-40"
            />
          </div>
          <div className="mr-2">
            <button onClick={handleFilter} className="bg-blue-500 text-white px-4 py-2">
              Apply Filter
            </button>
          </div>
          <div>
            <button onClick={clearFilter} className="bg-gray-500 text-white px-4 py-2">
              Clear Filter
            </button>
          </div>
        </div>

        {loading || !dataLoaded ? (
          <div>Loading...</div>
        ) : error ? (
          <div className='text-center text-3xl m-10 font-[500]'>{bookingMessage}</div>
        ) : !bookingList || bookingList.length === 0 ? (
          <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>
        ) : (
          <DataTable
            title="Booking List"
            columns={columns}
            data={Array.isArray(bookingList) ? bookingList : [bookingList]}
            customStyles={customStyles}
            pagination
            noHeader
          />
        )}
      </div>
      {editModalIsOpen && selectedRow &&
        <Modal isOpen={editModalIsOpen} style={modalCustomStyles}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h1>Are You Sure You Want to Delete shop?</h1>
            </div>
            <div className="body">
              <p>You won't able to retrieve it later!</p>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button onClick={deleteAppointment}>Continue</button>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default Shopper;

import React from 'react'
import { useSearchParams } from "react-router-dom"
import './Payment.css'
const PaymentSuccess = () => {

    const seachQuery = useSearchParams()[0]

    const referenceNum = seachQuery.get("reference")
    return (
        // <div>
        //     <div h="100vh" className='flex flex-col justify-center items-center'>

        //         <h2 textTransform={"uppercase"} className='font-xl'> Appoint Booked Successfully</h2>
        //         <br />
        //         <p>
        //             Reference No.{referenceNum}
        //         </p>

        //         <button>
        //             View Appointment
        //         </button>

        //     </div>
        // </div>

        <div class="card __success">
            <div style={{borderRadius:'200px', height:'200px', width:'200px', background: '#F8FAF5', margin:'0 auto'}}>
                <i class="checkmark">✓</i>
            </div>
            <h1>Success</h1> 
            <p>Your Appointment Booked Successfully!</p>
            <p>We received your payment request;<br/> Thank you for using HairBook.!</p>
        </div>
    )
}

export default PaymentSuccess
import React from 'react'
import Shops from '../components/Shopper/GetShops/GetShop'
const Setting = () => {
  return (
    <div>
      <Shops/>
    </div>
  )
}

export default Setting
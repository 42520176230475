import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { dispatchGetCurrentAppointments } from '../../redux/AdminRedux/getCurrentBookingsSlice';
const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = [
  {
    name: 'Customer',
    selector: 'customerName',
    sortable: true,
    marginTop: '10px',
    center: true,
  },
  {
    name: 'Appoint. Date',
    selector: 'appointmentDate',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  // {
  //   name: 'bookedDate',
  //   selector: 'bookedDate',
  //   sortable: true,
  //   center: true,
  //   marginTop: '10px',
  // },
  {
    name: 'Owner',
    selector: 'ownerName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Shop',
    selector: 'shopName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Staff Nane',
    selector: 'staffName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Slot',
    selector: 'slot',
    sortable: true,
    center: true,
    marginTop: '10px',
    cell: row => `${row.slot.startTime} - ${row.slot.endTime}`,
  },
  {
    name: 'Booked By',
    selector: 'bookedBy',
    sortable: true,
    center: true,
    marginTop: '10px',
    cell: row => row.bookedBy ? row.bookedBy : 'customer',
  },
];
const TodaysAppoints = () => {
  const { currentBooking, loading, error, message, success } = useAppSelector((state) => state.currentBooking);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    dispatch(dispatchGetCurrentAppointments())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentBooking || currentBooking.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Current Bookings</h1>
        <DataTable
          columns={columns}
          data={Array.isArray(currentBooking) ? currentBooking : [currentBooking]}
          customStyles={customStyles}
          pagination
          noHeader
        />
      </div>
    </>
  );
};

export default TodaysAppoints

import { createSlice } from "@reduxjs/toolkit"
import { getSCurrentAppointmentApi } from "../api/appointmentApis"

const initialState = {}

const getSCurrentAppointments = createSlice({
  name: "getSCurrentAppointments",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        currentSBooking: action.payload.appointments,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getSCurrentAppointments.actions
export default getSCurrentAppointments.reducer
export { clearError, clearMessage }

export const dispatchGetSCurrentAppointments = () => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getSCurrentAppointmentApi()
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}

import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetAllUsers } from '../../redux/AdminRedux/getAllCusomersSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { clearError, clearMessage } from "../../redux/getBookingsByOwner"

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = [
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    marginTop: '10px',
    center: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Phone Number',
    selector: 'phoneNumber',
    sortable: true,
    center: true,
    marginTop: '10px',
  },

  {
    name: 'Role',
    selector: 'role',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  // {
  //   name: 'shopName',
  //   selector: 'shopName',
  //   sortable: true,
  //   center: true,
  //   marginTop: '10px',
  // },
  // {
  //   name: 'slot',
  //   selector: 'slot',
  //   sortable: true,
  //   center: true,
  //   marginTop: '10px',
  //   cell: row => `${row.slot[0].startTime} - ${row.slot[0].endTime}`,
  // },
];
const AllCustomer = () => {
  const { customerList, loading, error, message, success } = useAppSelector((state) => state.getAllUsers);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(dispatchGetAllUsers({ role: "customer" }))
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!customerList || customerList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  return (
    <>

      <div className="container mx-auto p-4">
        <div className='flex items-center justify-between	'>
          <h1 className="text-2xl mb-4 text-[#333]">All Customers</h1>
          {/* <Link to="/users/new" className="link-btn">
              Add New
            </Link> */}

        </div>
        <DataTable
          title="Shop List"
          columns={columns}
          data={Array.isArray(customerList) ? customerList : [customerList]}
          customStyles={customStyles}
          pagination
          noHeader
        />
      </div>
    </>
  );
};

export default AllCustomer;

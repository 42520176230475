import axios from "axios"
import { server } from '../server'

export const getAdminShopsApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/customer/getAllShops`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getUsersApi = async (role) => {
  try {
    const { data } = await axios.get(`${server}/api/user/admin/getAllCustomers?role=${role}`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const editShopApi = async (shopData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/shopper/editShop`,
      shopData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const deleteShopApi = async (shopData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/shopper/deleteShop`,
      shopData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const deleteAppoitmentApi = async (appointmentData) => {
  try {
    const { data } = await axios.post(
      `${server}/api/user/admin/deleteAppointment`,
      appointmentData,
      { withCredentials: true }
    )
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getCurrentAppointmentApi = async () => {
  try {
    const { data } = await axios.get(`${server}/api/user/admin/todaysAppointment`, { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}

export const getBookingsByAdminApi = async (filters) => {
  try {
    const { data } = await axios.post(`${server}/api/user/admin/getBookingsByAdmin`, 
    filters,
    { withCredentials: true })
    return data
  } catch (error) {
    throw error?.response?.data
  }
}
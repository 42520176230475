import React from 'react'
import { useAppSelector } from '../../hooks/hook'
import { NavLink } from "react-router-dom";
import { AiOutlineCreditCard, AiOutlineAppstore, AiOutlineCalendar } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
const Subscription = () => {
  const { userData } = useAppSelector((state) => state.getLoggedUser)
  const { t } = useTranslation()
  return (
    (userData && userData?.role === 'customer' &&
      <>
        <li>
          <NavLink to={"/dashboard"} className="link">
            <AiOutlineAppstore size={23} className="min-w-max" />
            {t(`dashboard`)}
          </NavLink>
        </li>

        <li>
          <NavLink to={"/book-appointment"} className="link">
            <AiOutlineCalendar size={23} className="min-w-max" />
            Book New Appoint
          </NavLink>
        </li>
        <li>
          <NavLink to={"/subscription"} className="link">
            <AiOutlineCreditCard size={23} className="min-w-max" />
            Subscription
          </NavLink>
        </li>
      </>
    )
  )
}

export default Subscription
import React from 'react'
import CreateShop from '../components/Shopper/Create-Shop/CreateShop'
const Setting = () => {
  return (
    <div>
      <CreateShop/>
    </div>
  )
}

export default Setting
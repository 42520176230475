import { useEffect, useState } from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import {
  clearError,
  clearMessage,
  dispatchLogout
} from "../../redux/logOutUserSlice"
import { successToast } from "../../helper/toast"
import logo from '../../assets/images/logo.jpg'
// * React icons
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineProfile } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import Profile from "./Profile"
// import Shopper from "./Shopper"
import Subscription from "./Subscription";
import AdminSideBar from "./AdminSideBar";
import ShopperSideBar from "./ShopperSideBar";
const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const { t } = useTranslation()
  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
      open: {
        x: 0,
        width: "16rem",
        transition: {
          damping: 40,
        },
      },
      closed: {
        x: -250,
        width: 0,
        transition: {
          damping: 40,
          delay: 0.15,
        },
      },
    }
    : {
      open: {
        width: "16rem",
        transition: {
          damping: 40,
        },
      },
      closed: {
        width: "4rem",
        transition: {
          damping: 40,
        },
      },
    };
  const {
    loading: logoutLoading,
    success: logOutSuccess,
    message: logoutMessage,
    error: logoutError
  } = useAppSelector(state => state.logOutUser)

  const dispatch = useAppDispatch()

  const handleLogout = () => {
    dispatch(dispatchLogout())
  }

  if (logoutError && logoutMessage) {
    dispatch(clearError())
    dispatch(clearMessage())
  }

  if (logOutSuccess && logoutMessage) {
    successToast(logoutMessage)
    dispatch(clearMessage())
    window.location.reload()
  }

  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${open ? "block" : "hidden"
          } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" bg-white text-gray shadow-xl z-[999] max-w-[16rem]  w-[16rem] 
            overflow-hidden md:relative fixed
         h-screen "
      >
        {/* <div className="flex items-center gap-2.5 font-medium border-b py-3 border-slate-300  mx-3"> */}
        <div className="font-medium border-b py-3 border-slate-300  mx-3">
          <img
            src={logo}
            width="90%"
            alt=""
          />
          {/* <span className="text-xl whitespace-pre">Hair Book</span> */}
        </div>

        <Profile />

        <div className="flex flex-col h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[68%] h-[70%]">
            <Subscription />
            <AdminSideBar />
            <ShopperSideBar />
            <li>
              <NavLink to={"/profile"} className="link">
                <AiOutlineProfile size={23} className="min-w-max" />
                {t('profile')}
              </NavLink>
            </li>
            <li>
              <div className="link" onClick={handleLogout} disabled={logoutLoading}>
                <FiLogOut size={23} className="min-w-max" />
                {logoutLoading ? "Loading Wait" : "Logout"}
              </div>
            </li>
          </ul>
        </div>
        <motion.div
          onClick={() => {
            setOpen(!open);
          }}
          animate={
            open
              ? {
                x: 0,
                y: 0,
                rotate: 0,
              }
              : {
                x: -10,
                y: -200,
                rotate: 180,
              }
          }
          transition={{ duration: 0 }}
          className={`${open ? 'bottom-3' : ''} absolute w-fit h-fit md:block z-50 hidden right-2 cursor-pointer`}
        >
          <IoIosArrowBack size={25} />
        </motion.div>
      </motion.div>
      <div className="m-3 md:hidden" onClick={() => setOpen(true)}>
        <MdMenu size={25} />
      </div>
    </div>
  );
};

export default Sidebar;

import Reac, {useState, useEffect} from 'react'
import DataTable from 'react-data-table-component';
import { BsReceipt } from "react-icons/bs";
import { server } from '../../server';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { dispatchGetSubscriptions } from '../../redux/getSubscriptionSlice';
import axios from 'axios'
const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = [
  {
    name: 'Reference number',
    selector: 'payment_id',
    sortable: true,
    marginTop: '10px',
    // center: true,
    style: {minWidth: '200px'}
  },
  {
    name: 'Payment Method',
    selector: 'method',
    sortable: true,
    // center: true,
    marginTop: '10px',
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: true,
    // center: true,
    marginTop: '10px',
  },

  {
    name: 'Date',
    selector: 'payment_date',
    sortable: true,
    // center: true,
    marginTop: '10px',
  },
  {
    name: 'invoice',
    selector: 'receipt_no',
    sortable: true,
    // center: true,
    marginTop: '10px',
    cell: row => (
      <a href={`${server}/invoice/${row._id}`} target="_blank" rel="noopener noreferrer" style={{color: '#21528f', cursor: 'pointer'}}>
      <BsReceipt />
      </a>
    ),
  },
];
const Payments_Receipt = () => {
  const { subscriptionsList, loading, error, message, success } = useAppSelector((state) => state.getSubscriptionList);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    dispatch(dispatchGetSubscriptions())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!subscriptionsList || subscriptionsList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  const handleReceiptClick = async (row) => {
    const invoice_id = row._id
    const { data } = await axios.get(
      `${server}/invoice/${invoice_id}`, {invoice_id},
      { withCredentials: true }
    )
  }
  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Proof of Payment</h1>
        <DataTable
          // title="Booking List"
          columns={columns}
          data={Array.isArray(subscriptionsList) ? subscriptionsList : [subscriptionsList]}
          customStyles={customStyles}
          pagination
          noHeader
        />
      </div>
    </>
  );
};

export default Payments_Receipt



  // Define columns for the table
  // <BsReceipt />
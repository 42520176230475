import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from "react-toastify"
import { errorToast, successToast } from "../../helper/toast"
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import { clearError, clearMessage, dispatchBookAppointment } from "../../redux/bookAppointmentSlice"
import AlarmClock from "../common/clock/AlarmClock";
import axios from "axios";
import { server } from "../../server";
import { dispatchGetShopByOwner } from "../../redux/getShopByOwner";
import { dispatchGetAllCustomers } from "../../redux/getAllCustomers";
import ClipLoader from "react-spinners/ClipLoader";
import { AiFillPlusCircle } from "react-icons/ai";
import Select from 'react-select';

const ShopperAppoint = () => {
  const [ownerName, setOwnerName] = useState("");
  const [shopName, setShopName] = useState("");
  const [custName, setCustName] = useState("");
  const [custId, setCustId] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffDetails, setStaffDetails] = useState("");
  // const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [ownerID, setOwnerID] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [appointmentStartTime, setAppointmentStartTime] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState(null);
  const [slotsData, setSlotsData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const { error, message, success } = useAppSelector(state => state.appointmentData)
  const { userData } = useAppSelector(state => state.getLoggedUser)
  const { shopList = []} = useAppSelector(state => state.shopList)

  const { allCustList = [] } = useAppSelector((state) => state.getAllCustomers);

  const [selectedTime, setSelectedTime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [breakStartTime, setBreakStartTime] = useState('');
  const [breakEndTime, setBreakEndTime] = useState('');
  const [interval, setIntervalData] = useState('');
  const [shopStartTime, setShopStartTime] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [staffId, setStaffId] = useState("");
  const [breakSlots, setBreakSlots] = useState(null);
  const [shopServices, setShopServices] = useState([])
  const [service, setService] = useState([])

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  var appointment = [];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'black' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? 'black' : provided['&:hover'].borderColor,
      },
    }),
  };
  useEffect(() => {
    dispatch(dispatchGetShopByOwner())
    dispatch(dispatchGetAllCustomers())
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!ownerName || !shopName || !staffName || !selectedDate) {
      errorToast("All Fields required 🙄")
    } else {
      const appointmentData = { ownerId: ownerID, staffId, shopId, staffName, ownerName, shopName, appointmentDate: selectedDate, customerName: custName, customerId: custId, duration: selectedDuration, service }
      const { data } = await axios.post(
        `${server}/api/user/customer/checkAvailability`,
        appointmentData,
        { withCredentials: true }
      )
      appointment = data.data;
      setAppointmentTime(data.shopTiming)

      setShopStartTime(data.shopDetails.openingTime)
      setAppointmentEndTime(data.shopDetails.closingTime)

      setBreakStartTime(data.shopDetails.breakStartTime)
      setBreakEndTime(data.shopDetails.breakEndTime)
      setIntervalData(data.shopDetails.interval)
      setShopId(data.shopDetails.shopId)
      setSlotsData(data.data1)
      setBreakSlots(data.breakSlots)
    }
  };

  const confirmAppointment = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    dispatch(
      dispatchBookAppointment({
        startTime: shopStartTime,
        endTime: appointmentEndTime,
        breakStartTime: breakStartTime,
        breakEndTime: breakEndTime,
        interval: interval,
        timeSpan: selectedOption,
        ownerId: ownerID,
        staffName, ownerName, shopName,
        appointmentDate: selectedDate,
        customerName: custName,
        customerId: custId,
        duration: selectedDuration,
        selectedTime,
        bookSlots: slotsData,
        shopId: shopId,
        breakSlots: breakSlots,
        service
      })
    ).finally(() => {
      setIsSubmitting(false);
    });
  };
  const clearData = () => {
    // setOwnerName("")
    // setShopName("")
    // setSelectedSlot("")
    // setSelectedDate(null)
  }
  useEffect(() => {
    if (success && message) {
      successToast(message)
      navigate('/shopper/dashboard');
      dispatch(clearMessage());
      clearData()
    }
    if (error && message) {
      errorToast(message)
      dispatch(clearError())
      dispatch(clearMessage());
      clearData()
    }
  }, [success, error, message, dispatch]);

  const handleShopChange = (selectedShop) => {
    setShopName(selectedShop.label);
    const selectedShopData = shopList.find(shop => shop.shopName === selectedShop.label);

    console.log("selectedShopData; ", shopList, selectedShop)
    if (selectedShopData) {
      console.log("selectedshop: ", selectedShopData)
      setOwnerName(selectedShopData.ownerName);
      setOwnerID(selectedShopData.ownerId);
      setStaffDetails(selectedShopData.staffDetails);
      setShopId(selectedShopData._id);
      setShopServices(selectedShopData.services)
    } else {
      setOwnerName('');
    }
  };
  const handleCustChange = (selectedCustomer) => {
    setCustName(selectedCustomer.label);
    const selectedCustomerData = allCustList.find(customer => customer.name === selectedCustomer.label);
    if (selectedCustomerData) {
      setCustName(selectedCustomerData.name);
      setCustId(selectedCustomerData._id);
    } else {
      // Handle case when customer not found
    }
    // const selectedCustName = event.target.value;
    // const selectedCustomer = allCustList.find(customer => customer.name === selectedCustName);

    // if (selectedCustomer) {
    //   setCustName(selectedCustomer.name);
    //   setCustId(selectedCustomer._id);
    // } else {
    //   // Handle case when customer not found
    // }
  };

  const handleStaffChange = (event) => {
    const id = event.target.value;
    const selectedStaff = staffDetails.find(staff => staff._id === id);

    if (selectedStaff) {
      setStaffName(selectedStaff.name);
      setStaffId(selectedStaff._id);
    }
  };
  const handleDateChange = (date) => {
    if (date) {
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      console.log("Selected date (UTC):", utcDate);
      setSelectedDate(utcDate);
    } else {
      setSelectedDate(null);
    }
  };
  const handleDurationChange = (event) => {
    const selectedDurationValue = event.target.value;
    setSelectedDuration(selectedDurationValue);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleNewCustomer = (row) => {
    navigate(`/shopper/addCustomer`);
  };

  const handleServiceChange = (event) => {
    const id = event.target.value;
    console.log("id: ", id)
    const selectedService = shopServices.find(service => service._id === id);
    if (selectedService) {
      setService(selectedService);
      setSelectedDuration(selectedService.duration)
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="container mx-auto p-4">
        <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%]">
          <h5 className="text-[30px] font-Poppins text-center">Book New Appointment</h5>
          {/* create product form */}
          <form onSubmit={e => handleSubmit(e)}>
            <br />
            <div>
              <label className="pb-2">
                Select Shop <span className="text-red-500">*</span>
              </label>
              {/* <select
                className="w-full mt-2 border h-[35px] rounded-[5px]"
                value={shopName}
                onChange={handleShopChange}
              >
                <option value="Choose a category">Choose a shop</option>
                {shopList &&
                  shopList.map((i) => (
                    <option value={i.shopName} key={i.shopName}>
                      {i.shopName}
                    </option>
                  ))}
              </select> */}
              <Select
                value={shopName ? { label: shopName, value: shopName } : null}
                onChange={handleShopChange}
                options={shopList.map(shop => ({ label: shop.shopName, value: shop.shopName }))}
                placeholder="Search or Select a shop"
                className="w-full mt-2 border h-[35px] rounded-[5px]"
                styles={customStyles}
              />
            </div>
            <br />
            <div>

             <div className="flex justify-between">
                <label className="pb-2">
                  Select Customer <span className="text-red-500">*</span>
                </label>
                <button className="text-[#2066a9]" onClick={handleNewCustomer}><AiFillPlusCircle size={30}/></button>
              </div>

              <Select
                value={custName ? { label: custName, value: custName } : null}
                onChange={handleCustChange}
                options={allCustList.map(customer => ({ label: customer.name, value: customer.name }))}
                placeholder="Search or Select a customer"
                className="w-full mt-2"
                styles={customStyles}
              />
            </div>
            {/* <br /> */}
            {/* <div className="hidden">
              <label className="pb-2">
                Owner Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="ownerName"
                value={ownerName}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Owner name..."
                disabled
              />
            </div> */}
            <br /> 
            <div className="flex gap-5 justify-space-between sm:flex-nowrap flex-wrap">
              <div className="w-[100%] sm:w-[50%]">
                <label className="pb-2">
                  Select Staff <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px]"
                  value={staffId}
                  onChange={handleStaffChange}
                  disabled={!shopName}
                >
                  <option value="Choose a category">Choose a staff</option>
                  {staffDetails &&
                    staffDetails.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.name}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="w-[100%] sm:w-[50%]">
                <label className="pb-2">
                  Select Duration <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px]"
                  value={selectedDuration}
                  onChange={handleDurationChange}
                >
                  <option value="Choose a category">Choose a Duration</option>
                  <option value="20">20 minutes</option>
                  <option value="40">40 minutes</option>
                  <option value="60">60 minutes</option>
                </select>
              </div> */}
              <div className="w-[100%] sm:w-[50%]">
                <label className="pb-2">
                  Select Service <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px]"
                  value={service.id}
                  onChange={handleServiceChange}
                >
                  <option value="Choose a category">Choose a Duration</option>
                  {shopServices &&
                    shopServices.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.name}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <br />
            {/* <div className="flex gap-5 justify-space-between"> */}
            <div>
              <label className="pb-2">
                Select Date <span className="text-red-500">*</span>
              </label>
              <DatePicker
                className="w-full mt-2 border h-[35px] rounded-[5px] ml-2"
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()} // Disable past dates
                dateFormat="dd/MM/yyyy"
              />
            </div>

            {/* <br />
            <div>
              <label className="pb-2">
                Select availability <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center gap-5">
                <label>
                  <input
                    type="radio"
                    name="timeOption"
                    value="before"
                    checked={selectedOption === 'before'}
                    onChange={handleOptionChange}
                    className="mr-1"
                  />
                  Before 3 Hours
                </label>
                {selectedOption === 'before' && <label>
                  <span className="text-red-500">To see before 3 hrs you need to make some payment</span>
                </label>}
                <label>
                  <input
                    type="radio"
                    name="timeOption"
                    value="after"
                    checked={selectedOption === 'after'}
                    onChange={handleOptionChange}
                    className="mr-1"
                  />
                  After 3 Hours
                </label>
              </div>
            </div> */}

            <div className="w-52">
              <input
                // disabled={loading}
                type="submit"
                value={"Check available slot"}
                className="w-full bg-brandColor h-10 rounded-lg mt-5 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              />
            </div>

            <br />
            {selectedDate && shopName && slotsData &&
              (
                <div>
                  <label className="pb-2">
                    Booked slot <span className="text-red-500">*</span>
                  </label>

                  <div className="slot-container">
                    {slotsData.length > 0 && slotsData.map((slot, index) => (
                        <div
                          key={index}
                          className='booked slot'
                        // className={`slot ${slot.booked ? 'booked' : ''} ${selectedSlot === slot.slot ? 'selected' : ''}`}
                        // onClick={() => handleSlotClick(slot)}
                        >
                          {slot.startTime} - {slot.endTime}
                        </div>
                    )) 
                    }
                  </div>
                  {slotsData.length < 1 && <div className="w-full text-red-500">There is no booking now. you can book appointment from {appointmentTime}</div>}
                  {slotsData.length > 0 && <div className="w-full text-red-500 mt-2">You can book appointment from {appointmentTime}. Don't select timing between already booked slots</div>}
                </div>
              )
            }

            <br />
            {slotsData &&
            <label className="flex mt-3 items-center">
              Set Appointment Start Time :
            <AlarmClock selectedTime={selectedTime} setSelectedTime={setSelectedTime} className={'ml-6'}/>
            </label>}
            <br />

            {/* <DigitalClock/>  */}

            {selectedTime && (
              <div className="text-center mt-5 flex justify-center">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-3 flex items-center justify-center" onClick={confirmAppointment} disabled={isSubmitting}>
                  <span>Confirm Appointment</span>
                  <ClipLoader
                  color="white"
                  loading={isSubmitting}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>                         
            </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ShopperAppoint

import { createSlice } from "@reduxjs/toolkit"
import { editUserInfoApi } from "../api/userApis"

const initialState = {}

export const editUserInfoSlice = createSlice({
  name: "editUserInfoSlice",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        userData: action.payload.userData
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = editUserInfoSlice.actions
export default editUserInfoSlice.reducer
export { clearError, clearMessage }

export const dispatchEditUserInfo = shopData => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await editUserInfoApi(
      // JSON.parse(appointmentData),
      shopData
    )
    dispatch(setSuccess(response))
  } catch (error) {
    dispatch(setFailed(error))
  }
}


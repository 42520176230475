import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import ManageStaff from './ManageStaff'
import { dispatchGetAllUsers } from "../../../redux/AdminRedux/getAllCusomersSlice";
import { ToastContainer } from "react-toastify"
import { errorToast, successToast } from "../../../helper/toast"
import { useAppDispatch, useAppSelector } from "../../../hooks/hook"
import { clearError, clearMessage, dispatchCreateShop } from "../../../redux/createShopSlice"
import AlarmClock from "../../common/clock/AlarmClock";
import { useNavigate } from "react-router-dom";
const CreateShop = () => {
  const { userData } = useAppSelector(state => state.getLoggedUser)
  const [ownerId, setOwnerId] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('05:00');
  const [interval, setInterval] = useState('');
  const [breakStartTime, setBreakStartTime] = useState('01:00')
  const [breakEndTime, setBreakEndTime] = useState('02:00')
  const [numStaff, setNumStaff] = useState(0);
  const [staffDetails, setStaffDetails] = useState([]);
  const [shopName, setShopName] = useState([]);
  const [pricing, setPricing] = useState([]);
  const { loading, error, message, success } = useAppSelector(state => state.shopData)
  const { shopperList } = useAppSelector((state) => state.getAllUsers);
  const [selectedTime, setSelectedTime] = useState('10:00');
  const [services, setServices] = useState([])

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const handleNumStaffChange = (value) => {
    setNumStaff(parseInt(value));
    setStaffDetails(new Array(parseInt(value)).fill({}));
  };

  const handleStaffDetailsChange = (details) => {
    setStaffDetails(details);
  };
  const handleServiceChange = (index, event) => {
    const updatedServices = services.map((service, i) => 
        index === i ? { ...service, [event.target.name]: event.target.value } : service
    );
    setServices(updatedServices);
};

const addService = () => {
    setServices([...services, { name: '', amount: '', duration: '' }]);
};

const removeService = (index) => {
    setServices(services.filter((_, i) => i !== index));
};

  const handleOwnerChange = (event) => {
    const selectedOwnerName = event.target.value;
    const selectedOwner = shopperList.find(customer => customer.name === selectedOwnerName);

    if (selectedOwner) {
      setOwnerName(selectedOwner.name);
      setOwnerId(selectedOwner._id);
    } else {
      // Handle case when customer not found
    }
  };
  useEffect(() => {
    if (userData?.role === 'shopper') {
      setOwnerName(userData.name);
      setOwnerId(userData._id);
    }
  }, []); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPricing(prevPricing => ({
      ...prevPricing,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startTime || !interval || !endTime || !numStaff || !staffDetails) {
      errorToast("All Fields required 🙄")
    } else {
      dispatch(
        dispatchCreateShop({
          ownerId, ownerName, shopName, startTime, endTime, interval, numStaff, staffDetails, breakEndTime, breakStartTime,pricing, services
        })
      )
    }
  };

  const clearData = () => {
    setStartTime("")
    setEndTime("")
    setInterval()
    setBreakStartTime(null)
    setNumStaff(null)
    setStaffDetails(null)
    setShopName(null)
  }
  useEffect(() => {
    dispatch(dispatchGetAllUsers({ role: "shopper" }))
  }, [dispatch]);

  useEffect(() => {
    if (success && message) {
      successToast(message)
      dispatch(clearMessage());
      clearData()
      if(userData?.role === 'shopper') {
        navigate('/shopper/shops');
      }
      else{
        navigate('/admin/shops');
      }
    }
    if (error && message) {
      errorToast(message)
      dispatch(clearError())
      dispatch(clearMessage());
      // clearData()
    }
  }, [success, error, message, dispatch]);

  return (
    <>
      <ToastContainer />
      <div className="container mx-auto p-4">
        <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%] overflow-y-scroll h-[90vh]">
          <h5 className="text-[30px] font-Poppins text-center">Add New Shop</h5>
          {/* create product form */}
          <form onSubmit={handleSubmit}>
            {userData.name === 'Admin' &&
              <>
                <br />
                <div className="flex gap-5 justify-space-between">
                  <div className="w-full">
                    <label className="pb-2">
                      Select Shopkeeper <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      value={ownerName}
                      onChange={handleOwnerChange}
                    >
                      <option value="Choose a category">Choose a shop</option>
                      {shopperList &&
                        shopperList.map((i) => (
                          <option value={i.name} key={i._id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            }
            <br />
            <div className="flex gap-5 justify-space-between">
              <div className="w-full">
                <label className="pb-2">
                  Give unique shop name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="shopName"
                  value={shopName}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setShopName(e.target.value)}
                  placeholder="Give unique shop name..."
                />
              </div>
            </div>
            <br />
            <div className="flex gap-5 justify-space-between">
              <div className="w-[50%]">
                <label className="pb-2">
                  Start Time <span className="text-red-500">*</span>
                </label>
                {/* <input
                  type="number"
                  name="startTime"
                  value={startTime}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder="Starting time of appointments..."
                /> */}
                <AlarmClock selectedTime={startTime} setSelectedTime={setStartTime} className={'mt-3 block w-full'}/>
              </div>
              <div className="w-[50%]">
                <label className="pb-2">
                  End Time <span className="text-red-500">*</span>
                </label>
                {/* <input
                  type="number"
                  name="endTime"
                  value={endTime}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder="Ending time of appointments..."
                /> */}
                <AlarmClock 
                  selectedTime={endTime} setSelectedTime={setEndTime} className={'mt-3 block w-full'}/>
              </div>
            </div>
            <br />
            <div className="flex gap-5 justify-space-between">
              <div className="w-[49%]">
                <label className="pb-2">
                  Interval <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="interval"
                  value={interval}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setInterval(e.target.value)}
                  placeholder="Break between two appointment..."
                />
              </div>
              {/* <div className="w-[50%]">
            <label className="pb-2">
            No of Staff <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="nofPerson"
              value={nofPerson}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setNofPerson(e.target.value)}
              placeholder="Ending time of appointments..."
            />
          </div> */}
            </div>
            <br />
            <hr />
            <p className="mt-6 mb-2">Lunch/break</p>
            <div className="flex gap-5 justify-space-between">
              <div className="w-[50%]">
                <label className="pb-2">
                  Start Time of break
                </label>
                {/* <input
                  type="number"
                  name="interval"
                  value={breakStartTime}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setBreakStartTime(e.target.value)}
                  placeholder="Starting time of appointments..."
                /> */}
                <AlarmClock 
                  selectedTime={breakStartTime} setSelectedTime={setBreakStartTime} className={'mt-3 block w-full'}/>
              </div>
              <div className="w-[50%]">
                <label className="pb-2">
                  End Time of break
                </label>
                {/* <input
                  type="number"
                  name="interval"
                  value={breakEndTime}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setBreakEndTime(e.target.value)}
                  placeholder="Starting time of appointments..."
                /> */}
                 <AlarmClock 
                  selectedTime={breakEndTime} setSelectedTime={setBreakEndTime} className={'mt-3 block w-full'}/>
              </div>
            </div>
            <br />
            <hr/>
            <br />
            {/*  <label className="pb-2">
                Enter Amount for appointments <span className="text-red-500">*</span>
              </label>
            <div className="flex gap-5 justify-space-between">
              <div className="w-[30%]">
                <label className="pb-2">
                  20 minutes appointment <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="20min"
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="amount for 20 mins"
                  value={pricing['20min']}
                  onChange={handleChange}
                />
              </div>
              <div className="w-[30%]">
                <label className="pb-2">
                  40 minutes appointment <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="40min"
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="amount for 40 mins"
                  value={pricing['40min']}
                  onChange={handleChange}
                />
              </div>
              <div className="w-[30%]">
                <label className="pb-2">
                  60 minutes appointment <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="60min"
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="amount for 60 mins"
                  value={pricing['60min']}
                  onChange={handleChange}
                />
              </div>
            </div> */}

            <button type="button" className="service-btn mt-2 mb-2" onClick={addService}>Add Service</button>
                        {services.map((service, index) => (
                            <div key={index} className="service flex gap-2 sm:flex-nowrap flex-wrap">
                                <input
                                    type="text"
                                    name="name"
                                    value={service.name}
                                    placeholder="Service Name"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <input
                                    type="number"
                                    name="amount"
                                    value={service.amount}
                                    placeholder="amount"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <input
                                    type="number"
                                    name="duration"
                                    value={service.duration}
                                    placeholder="Duration (minutes)"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <button type="button" className="mt-2 service-btn" onClick={() => removeService(index)}>Remove</button>
                            </div>
                        ))}

            <br />
            <hr />
            {/* <ManageStaff/> */}
            <ManageStaff
              numStaff={numStaff}
              // setNumStaff={setNumStaff}
              onNumStaffChange={handleNumStaffChange}
              onStaffDetailsChange={handleStaffDetailsChange}
            />
            <div className="mx-auto w-52">
              <input
                // disabled={loading}
                type="submit"
                value={"Add Shop"}
                className="w-full bg-brandColor h-12 rounded-lg mt-6 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateShop;

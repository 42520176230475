import React from 'react'
import { useAppSelector } from '../hooks/hook'
import Shopper from '../components/Dashboard/Shopper'
import Customer from '../components/Dashboard/Customer'
import Admin from '../components/Dashboard/Admin'
const Dashboard = () => {
  const { userData } = useAppSelector((state) => state.getLoggedUser)
  return (
    <>
      {userData && userData?.role === 'shopper' &&
        <div className="mx-auto w-[100%]">
          <Shopper />
        </div>
      }
      {userData && userData?.role === 'customer' &&
        <div className="mx-auto w-[100%]">
          <Customer />
        </div>
      }
      {userData && userData?.role === 'admin' &&
        <div className="mx-auto w-[100%]">
          <Admin />
        </div>
      }
    </>
  )
}

export default Dashboard

import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetBookingsByCust } from '../../redux/getBookingsBycust';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { clearError, clearMessage } from "../../redux/getBookingsByOwner"

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = [
  // {
  //   name: 'Customer',
  //   selector: 'customerName',
  //   sortable: true,
  //   marginTop: '10px',
  //   center: true,
  // },
  {
    name: 'Appoint. Date',
    selector: 'appointmentDate',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  // {
  //   name: 'bookedDate',
  //   selector: 'bookedDate',
  //   sortable: true,
  //   center: true,
  //   marginTop: '10px',
  // },
  // {
  //   name: 'Owner',
  //   selector: 'ownerName',
  //   sortable: true,
  //   center: true,
  //   marginTop: '10px',
  // },
  {
    name: 'Shop',
    selector: 'shopName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'Booked By',
    selector: 'bookedBy',
    sortable: true,
    center: true,
    marginTop: '10px',
    cell: row => row.bookedBy ? row.bookedBy : 'You',
  },
];
const Customer = () => {
  const { custBookingList, loading, error, message, success } = useAppSelector((state) => state.custBookingList);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    dispatch(dispatchGetBookingsByCust())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(
  //     // dispatchBookAppointment({
  //     //   ownerName, shopName, slot: selectedSlot, appointmentDate: selectedDate, custName: userData.name, custId: userData._id
  //     // })
  //     dispatchGetAllShops()
  //   )
  // }, [dispatch]);
  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!custBookingList || custBookingList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">My Bookings</h1>
        <DataTable
          title="Booking List"
          columns={columns}
          // data={[custBookingList]}
          data={Array.isArray(custBookingList) ? custBookingList : [custBookingList]}
          customStyles={customStyles}
          pagination
        />
      </div>
    </>
  );
};

export default Customer;

import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaPhone, FaLocationDot, FaEnvelope, FaHouseUser } from "react-icons/fa6";
import saloon from '../../assets/images/saloon1.jpg'
import main from '../../assets/images/Hairbook2.png'
import about from '../../assets/images/Hairbook1.png'

function Main() {
    useEffect(() => {
        const nav = document.querySelector('#header nav');
        const toggle = document.querySelectorAll('nav .toggle');

        for (const element of toggle) {
            element.addEventListener('click', function () {
                nav.classList.toggle('show');
            });
        }

        const links = document.querySelectorAll('nav ul li a');

        for (const link of links) {
            link.addEventListener('click', function () {
                nav.classList.remove('show');
            });
        }

        const header = document.querySelector('#header');
        const navHeight = header.offsetHeight;

        function changeHeaderWhenScroll() {
            if (window.scrollY >= navHeight) {
                header.classList.add('scroll');
            } else {
                header.classList.remove('scroll');
            }
        }

        window.addEventListener('scroll', changeHeaderWhenScroll);

        return () => {
            window.removeEventListener('scroll', changeHeaderWhenScroll);
        };
    }, []);

    useEffect(() => {
        new Swiper('.swiper', {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            mousewheel: true,
            keyboard: true,
            breakpoints: {
                762: {
                    slidesPerView: 2,
                    setWrapperSize: true,
                },
            },
        });
    }, []);

    return (
        <main>
            {/* <!-- HOME --> */}
            <section class="section" id="home">
                <div class="container grid">
                    <div class="image">
                        <img src={saloon}
                            alt="" />
                    </div>
                    <div class="text">
                        <h2 class="title">Register Your Self at HAIRBOOK.</h2>
                        <p>
                            Run With Technology.
                        </p>
                        <a class="button" href="/signup">Register Now</a>
                    </div>
                </div>
            </section>

            <div class="divider-1"></div>

            {/* main content 
            <section class="section" id="main">
                <div class="container grid">
                    <div class="text">
                        <p>
                            ये भारत सरकार मान्य सर्व्हिस प्रोवाईडर है जो सलून ऑनर को ऊनके  Online Platform और Call द्वारा ऊनके सलून की अपोइमेन्टस
                            की सुविधा उपलब्ध करता है जहा Website पे आपके सलून के मालिक आप खुद होते हो.

                        </p>
                        <br />
                        <div>
                            <h4 className='mb-4 text-xl	'>Whom Need?</h4>
                            <p>
                                HAIRBOOK This platform is created for Saloon owners who haven't any type of online
                                platform for manage their daily scheduled appointments & They can say that they have
                                online platform!!!!
                            </p>
                        </div>
                        <br />
                        <div>
                            <h4 className='mb-4 text-xl	'>Why HAIRBOOK?</h4>
                            <p className='mb-2'>1{')'} Zero Fees. </p>
                            <p className='mb-2'>2{')'} We will Provide You Free Platform in Our Website.</p>
                            <p>3{')'} After The Bonding Of 6 Months With us You Will Get LED Screen for Groww saloon.
                                More Info. Contact Us.
                            </p>
                        </div>

                    </div>
                </div>
            </section>*/}
			<section class="section" id='about'>
                <div class="container grid">
                    <div class="image">
                        <img src={main}
                            alt="" />
                    </div>
                    <div class="" style={{fontSize: '22px',lineHeight: '36px',marginLeft:'5px'}}>
                        <p>Salon Appointment Start From 5 INR to as you wish.</p>
						<p>The certified barber means our artist also available here.</p>
						<p>We Provide only Hairbook verified Saloon’s service.</p>
                        <br/>
						<h2 style={{fontWeight: 'bold'}}>How Hairbook verified Saloon?</h2>
						<h6>The well train barber</h6>
						<h6>Their previous client’s feedback</h6>
						<h6>Their saloon’s condition as their price</h6>
						<h6>Hairbook Test Barber’s skills</h6>
						<h6>Hairbook also verified this saloon Physically</h6>
						<a class="button" href="/book-appointment" style={{marginTop:'10px'}}>Book Appointment</a>
					</div>
                </div>
            </section>

            <div class="divider-2"></div>
            {/* <!-- ABOUT --> */}
            <section class="section" id="home">
                <div class="container grid about">
                    <div class="image">
                        <img src={about} alt="about" />
                    </div>
                    <div class="text">
                        <h2 class="title">About Us</h2>
                      {/*  <p>
                        ये भारत सरकार मान्य सर्व्हिस प्रोवाईडर है जो सलून ऑनर को ऊनके  Online Platform और Call द्वारा ऊनके सलून की अपोइमेन्टस
                        की सुविधा उपलब्ध करता है जहा Website पे आपके सलून के मालिक आप खुद होते हो.

                        </p>
                        <br />
                        <div>
                           <h4 className='mb-4 text-xl	'>Whom Need?</h4>
                           <p>
                              HAIRBOOK This platform is created for Saloon owners who haven't any type of online
                              platform for manage their daily scheduled appointments & They can say that they have
                              online platform!!!!
                         </p>
                        </div>
                        <br />
                        
                            <h4 className='mb-4 text-xl	'>Why HAIRBOOK?</h4>*/}
							<div>
                            {/* <p className='mb-2'>1{') '} 
							Hairbook is on mission to make Hairbook India’s first Saloon Platform.
							//Zero Fees.
							</p>
                            <p className='mb-2'>2{') '} 
							Hairbook is beauty related platform where you get as Profational artist as you wish.
							We will Provide You Free Platform in Our Website.
							</p>
                            <p>3{') '} 
							For saloon owners hairbook provides space in our platform for manage their appointments.
							After The Bonding Of 6 Months With us You Will Get LED Screen for Groww saloon.
                            More Info. Contact Us.
                            </p>
							<p>4{') '}
							  We also provide some screen for do digitalization of your saloon.
                            </p>
							<p>5{') '}
							More info. Contact us.
							</p> */}
                            <ul>
                                <li>Hairbook is on mission to make Hairbook India’s first Saloon Platform.</li>
                                <li>Hairbook is beauty related platform where you get as Profational artist as you wish.</li>
                                <li>For saloon owners hairbook provides space in our platform for manage their appointments.</li>
                                <li>We also provide some screen for do digitalization of your saloon.</li>
                                <li>More info. Contact us.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            <div class="divider-2"></div>

            {/* <!-- CONTACT --> */}
            <section class="section" id="contact">
                <div class="container grid">
                    <div class="text">
                        <h2 class="title">Contact Us!</h2>
                        <p>
                            Get in touch with HairBook, we want to answer your questions, hear your criticisms and
                            suggestions.

                        </p>
                        <a href="https://api.whatsapp.com/send?phone=+917862072217" class="button" target="_blank"><i class="icon-whatsapp"></i> Get in touch</a>
                    </div>
                    <div class="links">
                        <ul class="grid">
                            {/*//<li><FaHouseUser color='hsl(var(--hue) 36% 57%)' size={20} className='mr-6' />Bhaushan Jadhav</li>
                             <li><FaPhone color='hsl(var(--hue) 36% 57%)' size={20} className='mr-6' /> +91-9724795694</li>*/}
                            <li><FaPhone color='hsl(var(--hue) 36% 57%)' size={20} className='mr-6' /> +91-9851686111</li>
                            {/*<li><FaLocationDot color='hsl(var(--hue) 36% 57%)' size={20} className='mr-6' /> Surat, Gujarat</li>*/}
                            <li><FaEnvelope color='hsl(var(--hue) 36% 57%)' size={20} className='mr-6' /> admin@hairbook.in</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div class="divider-1"></div>
        </main>
    );
}

export default Main;

import { useAppSelector } from "../hooks/hook";
import { Navigate } from "react-router-dom";

const ProtectedShopperRoute = ({ children }) => {
  const { loading, userData } = useAppSelector((state) => state.getLoggedUser);
  if (loading === false) {
    if (userData.role !== "shopper") {
      return <Navigate to="/login" replace />;
    }
    return children;
  }
};

export default ProtectedShopperRoute;

import React, { useState, useRef, useEffect } from 'react';
import './AlarmClock.css';

function AlarmClock({ selectedTime, setSelectedTime, className }) {
  const [time, setTime] = useState(new Date());
  const [isAlarmSet, setIsAlarmSet] = useState(false);
  const inputRef = useRef(null);

  const updateAlarmTime = (event) => {
    const selectedTimeString = event.target.value;
    setSelectedTime(selectedTimeString);
  };

  const clearAlarm = () => {
    setIsAlarmSet(false);
  };

  const checkAlarm = () => {
    if (selectedTime) {
      const alarmHours = parseInt(selectedTime.split(':')[0], 10);
      const alarmMinutes = parseInt(selectedTime.split(':')[1], 10);

    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    if (currentHours === alarmHours && currentMinutes === alarmMinutes) {
      // alert('Alarm!');
      clearAlarm();
     }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
      checkAlarm();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [selectedTime]);

  return (
    <div onClick={() => inputRef.current && inputRef.current.showPicker()} className={`alarm-clock-container ${className}`}>
      <input
        ref={inputRef}
        type="time"
        value={selectedTime}
        className={`alarm-clock-input border ${className}`}
        onChange={updateAlarmTime}
      />
    </div>
  );
}

export default AlarmClock;

import React from 'react'
import Main from './Main'
import Footer from './Footer'
import Header from './Header'
import './main.css'
const Home = () => {
    return (
        <div className='main'>
            <Header />
            <Main />
            <Footer />
        </div>
    )
}

export default Home
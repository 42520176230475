import React from 'react'
import { useAppSelector } from '../../hooks/hook'
import { NavLink } from "react-router-dom";
// import { AiOutlineCreditCard } from "react-icons/ai";
import { FaShop, FaHouseUser, FaUser, FaCreditCard, FaCalendar, FaAppStore } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const AdminSideBar = () => {
    const { userData } = useAppSelector((state) => state.getLoggedUser)
    const { t }= useTranslation()
    return (
        (userData && userData?.role === 'admin' && (userData?.email === 'admin@hairbook.in' || 'admin@gmail.com') &&
            <>
                <li>
                    <NavLink to={"admin/todays"} className="link">
                        <FaAppStore size={23} className="min-w-max" />
                        {/* {t(`dashboard`)} */}
                        Today's Appointments
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"admin/dashboard"} className="link">
                        <FaAppStore size={23} className="min-w-max" />
                        {/* {t(`dashboard`)} */}
                        All Appointments
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/admin/all-customers"} className="link">
                        {/* <FaRegUser size={23} className="min-w-max" /> */}
                        <FaUser size={23} className="min-w-max" />
                        Customers
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/admin/book-appointment"} className="link">
                        <FaCalendar size={23} className="min-w-max" />
                        Book New Appoint
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/admin/all-sellers"} className="link">
                        <FaHouseUser size={23} className="min-w-max" />
                        Shoppers
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/admin/all-shops"} className="link">
                        <FaShop size={23} className="min-w-max" />
                        Shops
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/admin/all-payments"} className="link">
                        <FaCreditCard size={23} className="min-w-max" />
                        Payments
                    </NavLink>
                </li>
            </>
        )
    )
}

export default AdminSideBar
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import ManageStaff from '../Shopper/Create-Shop/ManageStaff';
import { ToastContainer } from "react-toastify";
import { errorToast, successToast } from "../../helper/toast";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { clearError, clearMessage, dispatchEditShop } from "../../redux/AdminRedux/editShopSlice";
import { useLocation } from 'react-router-dom';
import AlarmClock from "../common/clock/AlarmClock";

const EditShop = () => {
    const location = useLocation();
    const { shop } = location.state;
    console.log("shop.startTime: ", shop.startTime)
    const navigate = useNavigate()
    // const { userData } = useAppSelector(state => state.getLoggedUser)
    const [startTime, setStartTime] = useState(shop.startTime);
    const [endTime, setEndTime] = useState(shop.endTime);
    const [interval, setInterval] = useState(shop.interval);
    const [breakStartTime, setBreakStartTime] = useState(shop.breakStartTime)
    const [breakEndTime, setBreakEndTime] = useState(shop.breakEndTime)
    const [numStaff, setNumStaff] = useState(shop.numStaff);
    const [staffDetails, setStaffDetails] = useState(shop.staffDetails);
    const [shopName, setShopName] = useState(shop.shopName);
    const [pricing, setPricing] = useState(shop.pricing[0]);
    // const [services, setServices] = useState([{ name: '', amount: '', duration: '' }]);
    const [services, setServices] = useState(shop.services || [])

    useAppSelector(state => state.shopData)
    const { error, message, success } = useAppSelector(state => state.editShopData)
    const dispatch = useAppDispatch();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPricing(prevPricing => ({
          ...prevPricing,
          [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(startTime ,interval,endTime)
        if (!startTime || !endTime) {
            errorToast("All Fields required 🙄");
        } else {
            dispatch(dispatchEditShop({
                ownerId: shop.ownerId, ownerName: shop.ownerName, shopName, startTime, endTime, interval, breakEndTime, breakStartTime, shopId: shop._id, staffDetails, pricing, services
            }));
        }
    };

    const handleNumStaffChange = (value) => {
        setNumStaff(parseInt(value));
        setStaffDetails(new Array(parseInt(value)).fill({}));
    };
    
    const handleStaffDetailsChange = (details) => {
        setStaffDetails(details);
    };

    const clearData = () => {
        setStartTime("");
        setEndTime("");
        setInterval("");
        setBreakStartTime(null);
        setNumStaff(null);
        setStaffDetails(null);
        setShopName(null);
    };

    const handleServiceChange = (index, event) => {
        const updatedServices = services.map((service, i) => 
            index === i ? { ...service, [event.target.name]: event.target.value } : service
        );
        setServices(updatedServices);
    };

    const addService = () => {
        setServices([...services, { name: '', amount: '', duration: '' }]);
    };

    const removeService = (index) => {
        setServices(services.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (success && message) {
            successToast(message);
            dispatch(clearMessage());
            clearData();
            setTimeout(() => {
                navigate("/admin/all-shops");
            }, 2000);
        }
        if (error && message) {
            errorToast(message);
            dispatch(clearError());
            dispatch(clearMessage());
            // clearData();
        }
    }, [success, error, message, dispatch]);

    return (
        <>
            <ToastContainer />
            <div className="container mx-auto p-4">
                <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%] overflow-y-scroll h-[90vh]">
                    <h5 className="text-[30px] font-Poppins text-center">Book New Appointment</h5>
                    <form onSubmit={handleSubmit}>
                        <br />
                        <div className="flex gap-5 justify-space-between">
                            <div className="w-full">
                                <label className="pb-2">
                                    Give unique shop name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="shopName"
                                    value={shopName}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setShopName(e.target.value)}
                                    placeholder="Give unique shop name..."
                                />
                            </div>
                        </div>
                        <br />
                        <div className="flex gap-5 justify-space-between">
                            <div className="w-[50%] sm:w-[100%]">
                                <label className="pb-2">
                                    Start Time <span className="text-red-500">*</span>
                                </label>
                                <AlarmClock selectedTime={startTime} setSelectedTime={setStartTime} className={'mt-3 block w-full'}/>
                            </div>
                            <div className="w-[100%] sm:w-[50%]">
                                <label className="pb-2">
                                    End Time <span className="text-red-500">*</span>
                                </label>
                                <AlarmClock selectedTime={endTime} setSelectedTime={setEndTime} className={'mt-3 block w-full'}/>
                            </div>
                        </div>
                        <br />
                        <div className="flex gap-5 justify-space-between">
                            <div className="w-[49%]">
                                <label className="pb-2">
                                    Interval <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="number"
                                    name="interval"
                                    value={interval}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setInterval(e.target.value)}
                                    placeholder="Interval between appointments..."
                                />
                            </div>
                        </div>
                        <br />
                        <hr />
                        <p className="mt-6 mb-2">Lunch/break</p>
                        <div className="flex gap-5 justify-space-between ">
                            <div className="w-[50%]">
                                <label className="pb-2">
                                    Start Time of break
                                </label>
                                {/* <input
                                    type="text"
                                    name="breakStartTime"
                                    value={breakStartTime}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setBreakStartTime(e.target.value)}
                                    placeholder="Starting time of appointments..."
                                /> */}
                                <AlarmClock 
                                    selectedTime={breakStartTime} setSelectedTime={setBreakStartTime} className={'mt-3 block w-full'}/>
                            </div>
                            <div className="w-[50%]">
                                <label className="pb-2">
                                    End Time of break
                                </label>
                                {/* <input
                                    type="text"
                                    name="breakEndTime"
                                    value={breakEndTime}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setBreakEndTime(e.target.value)}
                                    placeholder="Starting time of appointments..."
                                /> */}
                                <AlarmClock 
                                     selectedTime={breakEndTime} setSelectedTime={setBreakEndTime} className={'mt-3 block w-full'}/>
                            </div>
                        </div>
                        <br />
                        <hr/>
                        <br />
                         {/*<label className="pb-2">
                            Enter Amount for appointments <span className="text-red-500">*</span>
                        </label>
                        <div className="flex gap-5 justify-space-between">
                        <div className="w-[30%]">
                            <label className="pb-2">
                            20 minutes appointment <span className="text-red-500">*</span>
                            </label>
                            <input
                            type="number"
                            name="20min"
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="amount for 20 mins"
                            value={pricing['20min']}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="w-[30%]">
                            <label className="pb-2">
                            40 minutes appointment <span className="text-red-500">*</span>
                            </label>
                            <input
                            type="number"
                            name="40min"
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="amount for 40 mins"
                            value={pricing['40min']}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="w-[30%]">
                            <label className="pb-2">
                            60 minutes appointment <span className="text-red-500">*</span>
                            </label>
                            <input
                            type="number"
                            name="60min"
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="amount for 60 mins"
                            value={pricing['60min']}
                            onChange={handleChange}
                            />
                        </div>
                        </div> */}
                        
                        <button type="button" className="service-btn mt-2 mb-2" onClick={addService}>Add Service</button>
                        {services.map((service, index) => (
                            <div key={index} className="service flex gap-2 sm:flex-nowrap flex-wrap">
                                <input
                                    type="text"
                                    name="name"
                                    value={service.name}
                                    placeholder="Service Name"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <input
                                    type="number"
                                    name="amount"
                                    value={service.amount}
                                    placeholder="amount"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <input
                                    type="number"
                                    name="duration"
                                    value={service.duration}
                                    placeholder="Duration (minutes)"
                                    onChange={(event) => handleServiceChange(index, event)}
                                    required
                                    className="mt-2 appearance-none block w-full px-3 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <button type="button" className="mt-2 service-btn" onClick={() => removeService(index)}>Remove</button>
                            </div>
                        ))}
                        <br />
                        <hr />
                        <ManageStaff
                          numStaff={numStaff}
                          onNumStaffChange={handleNumStaffChange}
                          onStaffDetailsChange={handleStaffDetailsChange}
                          initialStaffDetails={staffDetails}
                        />
                        <div className="mx-auto w-52">
                            <input
                                // disabled={loading}
                                type="submit"
                                value={"Update Slots"}
                                className="w-full bg-brandColor h-12 rounded-lg mt-6 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EditShop;                         
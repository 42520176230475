import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetAdminShop } from '../../redux/AdminRedux/getAdminShopsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { Link, useNavigate } from 'react-router-dom';
import { BsTrash3 } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import Modal from 'react-modal';
import "./modal.css";
import { successToast, errorToast } from '../../helper/toast';
import {  clearMessage, dispatchDeleteShop } from "../../redux/AdminRedux/deleteShopSLice"
import { FaUser } from "react-icons/fa6";

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const modalCustomStyles = {
  content: {
    top: '10%',
    left: '30%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};
const CustomLoader = () => (
  <div>
    <i className="fas fa-circle-notch fa-spin fa-5x text-secondary"></i>
  </div>
);

const AllShops = () => {
  const { allShopList, loading, error } = useAppSelector(
    (state) => state.allShopList
  );
  const { message, success } = useAppSelector(state => state.deleteShop)
  const dispatch = useAppDispatch();

  const navigate = useNavigate()
  const handleEdit = (row) => {
    navigate(`/admin/edit/${row._id}`, { state: { shop: row } });
  };
  const handleStaffsBreak = (row) => {
    navigate(`/admin/staffEdit/${row._id}`, { state: { shop: row } });
  };
  
  useEffect(() => {
    if (message && success) {
      dispatch(dispatchGetAdminShop())
      successToast(message)
      dispatch(clearMessage());
      setEditModalIsOpen(false)
      // setTimeout(() => {
      //   navigate("/admin/all-shops")
      // }, 2000);
    }
    // if (deleteMessage) {
    //   errorToast(message)
    //   dispatch(clearError())
    //   dispatch(clearMessage());
    // }
  }, [message, dispatch]);


  const handleDelete = (row) => {
    setEditModalIsOpen(true)
    setSelectedRow(row._id);
  };
  const deleteShop = () => {
    dispatch(
      dispatchDeleteShop({ shopId: selectedRow })
    )
  };

  const columns = [
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row)} className='ml-2'><FaRegEdit size={20} /></button>
          <button onClick={() => handleDelete(row)} className='ml-2'><BsTrash3 size={20} /></button>
          <button onClick={() => handleStaffsBreak(row)} className='ml-2'><FaUser size={20} /></button>
        </>
      ),
      // center: true,
    },

    {
      name: 'shopName',
      selector: 'shopName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'OwnerName',
      selector: 'ownerName',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'startTime',
      selector: 'startTime',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'endTime',
      selector: 'endTime',
      sortable: true,
      marginTop: '10px',
      center: true,
    },
    {
      name: 'breakStartTime',
      selector: 'breakStartTime',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'breakEndTime',
      selector: 'breakEndTime',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'interval',
      selector: 'interval',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
    {
      name: 'numStaff',
      selector: 'numStaff',
      sortable: true,
      center: true,
      marginTop: '10px',
    },
  ];

  const [dataLoaded, setDataLoaded] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    dispatch(dispatchGetAdminShop())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);

  if (loading || !dataLoaded) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!allShopList || allShopList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  return (
    <>
      <div className="container mx-auto p-4">
        <div className='flex items-center justify-between	'>
          <h1 className="text-2xl mb-4 text-[#333]">All Shops</h1>
          {/* <Link to="/admin/generate-slots" className="link-btn">
            Add New
          </Link> */}

        </div>
        <DataTable
          title={<Link to="/admin/generate-slots" className="link-btn">
            Add New
          </Link>}
          columns={columns}
          data={Array.isArray(allShopList) ? allShopList : [allShopList]}
          customStyles={customStyles}
          pagination
        // noHeader
        />
      </div>

      {editModalIsOpen && selectedRow &&
        <Modal isOpen={editModalIsOpen} style={modalCustomStyles}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h1>Are You Sure You Want to Delete shop?</h1>
            </div>
            <div className="body">
              <p>You won't able to retrieve it later!</p>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setEditModalIsOpen(false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button onClick={deleteShop}>Continue</button>
            </div>
          </div>

        </Modal>
      }
    </>
  );
};

export default AllShops;

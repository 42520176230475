import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';

const Checkout = () => {
  const location = useLocation();
  const appointmentData = JSON.parse(localStorage.getItem("userAppointmentData"));
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [bookingDetails, setBookingDetails] = useState('');
  const [amount, setAmount] = useState('');
  const AmountMapping = {
    '20': '20min',
    '40': '40min',
    '60': '60min',
  }
  // Function to get the cookie value
  const getCookie = () => {
    const cookieVal = JSON.parse(Cookies.get('bookingDetails')); // Change 'yourCookieName' to the name of your cookie
    if (cookieVal) {
      setBookingDetails(cookieVal);
    } else {
      setBookingDetails('Cookie not found');
    }
  };

  // Call getCookie() when the component mounts
  useEffect(() => {
    getCookie();
  }, []);

  useEffect(() => {
    const getPricingAmount = async () => {
      try {
        console.log("bookingDetails.service[0].name: ", bookingDetails.service._id)
        const { data } = await axios.get(
          `${server}/api/user/customer/getPricing?shopId=${bookingDetails.shopId}&service=${bookingDetails.service._id}`,
          { withCredentials: true }
        );
        //var map = AmountMapping[bookingDetails.duration]
        //setAmount(data.list[map]);
		setAmount(data.list);
        //console.log("API Response Data: ", data, bookingDetails.duration, "map : ", map); // Log API response data
      } catch (error) {
        console.error("Failed to fetch staff details:", error);
      } finally {
        // setLoading(false); // Set loading to false once data is fetched
      }
    };
    if(bookingDetails) getPricingAmount();
  }, [bookingDetails]);


    const paymentData ={
        name: name ,//appointmentData.customerName,
        amount: amount, // bookingDetails.amount,
        phoneNumber: phoneNumber,
        transactionId: 'T' + Date.now(),
    }

    const handleSubmit = async (e)=>{
      e.preventDefault();
      setLoading2(true);

      const { data: { key } } = await axios.get(`${server}/api/getkey`)

      const { data: { order } } = await axios.post(`${server}/api/payment/razorpay/checkout`, {paymentData, bookingDetails}, { withCredentials: true })
      setTimeout(() => {
          setLoading2(false);
      }, 1500);

      const options = {
          key,
          amount: order.amount,
          currency: "INR",
          name: "Pallavi Patil",
          description: "HairBook",
          // image: "https://avatars.githubusercontent.com/u/25058652?v=4",
          order_id: order.id,
          callback_url: `${server}/api/payment/razorpay/paymentverification`,
          handler: function(response) {
            window.location.href = '/paymentsuccess?reference=' + response.razorpay_payment_id;
          },
          prefill: {
              name: "Pallavi",
              email: "pallavi@example.com",
              contact: "9999999999",
              userId: "653250ef020c4515e0d87b88"
          },
          notes: {
              "address": "Razorpay Corporate Office"
          },
          theme: {
              "color": "#121212"
          },
          modal: {
            ondismiss: async function() {
              window.location.href = '/dashboard'
                // const cancel = await axios.post(`${server}/api/payment/razorpay/cancel`, {paymentData, bookingDetails}, { withCredentials: true })
            }
        }
      };
      const razor = new window.Razorpay(options);
      razor.open();
    }
    const handleSubmit1 = async (e)=>{
        e.preventDefault();
        setLoading2(true);

        await axios.post(`${server}/api/payment/razorpay/paymentverification`, {paymentData, bookingDetails}, { withCredentials: true }).then(res => {  
        setTimeout(() => {
            setLoading2(false);
        }, 1500);
        })
        .catch(error => {
            setLoading2(false)
            console.error(error);
        });   
    }
  return (
    <div className="container mx-auto p-4">
      <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%]">
      {bookingDetails && bookingDetails.slot &&<p>Your appointment will be scheduled &nbsp;
        <b>{bookingDetails.slot[0].startTime} To {bookingDetails.slot[0].endTime}</b>. To confirm appointment make the payment</p>}
        <h5 className="text-[30px] font-Poppins text-center">Checkout</h5>
        <form onSubmit={handleSubmit}>
          <br />
          <div>
            <label className="pb-2">Amount</label>
            <input
              type="text"
              className="w-full mt-2 border h-[35px] rounded-[5px] px-3"
              value={`₹${amount}`} // Assuming duration is the amount
              disabled
            />
          </div>
          <br />
          <div>
            <label className="pb-2">Name</label>
            <input
              type="text"
              className="w-full mt-2 border h-[35px] rounded-[5px] px-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <br />
         
          <div>
            <label className="pb-2">Email</label>
            <input
              type="email"
              className="w-full mt-2 border h-[35px] rounded-[5px] px-3"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <br />
          <div>
            <label className="pb-2">Phone Number</label>
            <input
              type="tel"
              className="w-full mt-2 border h-[35px] rounded-[5px] px-3"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <br />
          {/* <div className="mx-auto w-52">
            <input
              type="submit"
              value="Proceed to Pay"
              className="w-full bg-brandColor h-12 rounded-lg mt-5 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
            />
          </div> */}
                {!loading2? <div className='col-12 center'>
                    <button className='w-100 bg-blue-500 text-white px-4 py-2 rounded-lg mb-3' type="submit">Pay Now</button>
                </div>
                :
                <div className='col-12 center'>
                    <button className='w-100 text-center' type="submit">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden ">Wait...</span>
                    </div>
                    </button>
                </div>
                }

        </form>
      </div>
    </div>

  );
};

export default Checkout;

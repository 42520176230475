import { configureStore } from "@reduxjs/toolkit"
import sendOtpReducer from "./sendOtpSlice"
import verifyAndRegisterReducer from "./verifyAndRegisterAndAuthSlice"
import resetOtpReducer from "./resendOtpSlice"
import getLoggedUserReducer from "./getLoginUserSlice"
import logoutUserReducer from "./logOutUserSlice"
import bookAppointmentSlice from "./bookAppointmentSlice"
import createShopSlice from "./createShopSlice"
import getShopByOwner from "./getShopByOwner"
import getBookingsByOwner from "./getBookingsByOwner"
import getBookingsBycust from "./getBookingsBycust"
import getAdminShops from "./AdminRedux/getAdminShopsSlice"
import editShopSlice from "./AdminRedux/editShopSlice"
import deleteShopSLice from "./AdminRedux/deleteShopSLice"
import getAllCusomersSlice from "./AdminRedux/getAllCusomersSlice"
import getAllCustomers from "./getAllCustomers"
import getSubscriptionSlice from "./getSubscriptionSlice"
import getCurrentBookings from "./AdminRedux/getCurrentBookingsSlice"
import getBookingsSlice from "./AdminRedux/getBookingsSlice"
import getSCurrentBookingsSlice from "./getSCurrentBookingsSlice"
import deleteAppoitmentSLice from "./AdminRedux/deleteAppoitmentSLice"
import deleteAppointmentShopper from "./deleteAppointmentShopper"
import editUserInfoSlice from "./editUserInfoSlice"

const store = configureStore({
  reducer: {
    sendOtp: sendOtpReducer,
    verifyAndRegisterAndAuth: verifyAndRegisterReducer,
    resetOtp: resetOtpReducer,
    getLoggedUser: getLoggedUserReducer,
    logOutUser: logoutUserReducer,
    appointmentData: bookAppointmentSlice,
    shopData: createShopSlice,
    editShopData: editShopSlice,
    shopList: getShopByOwner,
    allShopList: getAdminShops,
    bookingList: getBookingsByOwner,
    custBookingList: getBookingsBycust,
    deleteShop: deleteShopSLice,
    getAllUsers: getAllCusomersSlice,
    getAllCustomers: getAllCustomers,
    getSubscriptionList: getSubscriptionSlice,
    currentBooking: getCurrentBookings,
    currentSBooking: getSCurrentBookingsSlice,
    adminBookingList: getBookingsSlice,
    deleteAppointment: deleteAppoitmentSLice,
    delShopAppointData: deleteAppointmentShopper,
    editUserInfo: editUserInfoSlice
  }
})

export default store

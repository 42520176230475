import React from 'react'
import { useSearchParams } from "react-router-dom"
import './Payment.css'
const PaymentFailed = () => {

    const seachQuery = useSearchParams()[0]

    const referenceNum = seachQuery.get("reference")
    return (
        <div class="card __success">
            <div style={{borderRadius:'200px', height:'200px', width:'200px', background: '#F8FAF5', margin:'0 auto'}}>
                <i class="checkmark">✓</i>
            </div>
            <h1>Success</h1> 
            <p>Your Appointment is not Booked!</p>
            <p>Your last payment was failed!</p>
        </div>
    )
}

export default PaymentFailed
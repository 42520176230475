// Header.js
import React, {useState} from 'react';
import { AiOutlineMenu, AiOutlineClose  } from "react-icons/ai";

function Header() {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <header id="header">
      <nav className={`container ${showNav ? 'show' : ''}`}>
        <a className="logo" href="/">hair<span>book</span>.</a>
        <div className="menu">
          <ul className="grid">
            <li><a className="title" href="#home">Home</a></li>
            <li><a className="title" href="#about">About</a></li>
            {/* <li><a className="title" href="#services">Services</a></li>
            <li><a className="title" href="#testimonials">Testimonials</a></li> */}
            <li><a className="title" href="#contact">Contact</a></li>
            <li><a className="title" href="/login">Login</a></li>
          </ul>
        </div>
        {/* <AiOutlineMenu className={`toggle icon-menu block sm:hidden ${showMenu ? 'hidden' : ''}`} color='#6ab9ac' size={22} onClick={toggleMenu} />
        <AiOutlineClose className={`toggle icon-close block sm:hidden ${showMenu ? '' : 'hidden'}`} color='#6ab9ac' size={22} onClick={toggleMenu} /> */}

        <AiOutlineMenu className="toggle icon-menu" onClick={toggleNav}/>
        <AiOutlineClose className="toggle icon-close" onClick={toggleNav}/>
      </nav>
    </header>
  );
}
export default Header;

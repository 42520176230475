import React, { useState, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import { successToast, errorToast } from "../helper/toast"
import { useAppDispatch, useAppSelector } from "../hooks/hook"
import { dispatchEditUserInfo } from "../redux/editUserInfoSlice.js"
import { clearError, clearMessage, dispatchSentOtp } from "../redux/sendOtpSlice"
import { validatePhoneNumber } from "../helper/validation"
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { server } from '../server';
const Appointment = () => {
  const { userData } = useAppSelector(state => state.getLoggedUser)
  const { error, message, success } = useAppSelector(state => state.sendOtp)
  const {
    error: editUserInfoError,
    message: editUserInfoMessage,
    success: editUserInfoSuccess
  } = useAppSelector(state => state.editUserInfo);
  
  const [name, setName] = useState(userData && userData.name);
  const [email, setEmail] = useState(userData && userData.email);
  const [phoneNumber, setPhoneNumber] = useState(userData && userData.phoneNumber);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handelUpdate = (e) => {
    e.preventDefault();
    dispatch(dispatchEditUserInfo({ name, email, phoneNumber }));
  };
  const handelUpdatePswd = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${server}/api/user/update-user-password`,
        {
          oldPassword,
          newPassword,
        },
        { withCredentials: true }
      )
      .then((res) => {
        successToast(res.data.message)
        setOldPassword('')
        setNewPassword('')
      })
      .catch((err) => {
        errorToast(err.response.data.message)
      });
  };
  const handelOtpSubmit = async e => {
    e.preventDefault()
    const isValidPhoneNumber = validatePhoneNumber(phoneNumber)
    if (isValidPhoneNumber)
      dispatch(
        dispatchSentOtp({
          phoneNumber: `${countryCode.trim()}${phoneNumber.trim()}`, isUpdate: true, oldNumber: userData.phoneNumber
        })
      )
  }

  useEffect(() => {
    if (success && message) {
      successToast(`OTP Sent To ${phoneNumber}`)
      dispatch(clearMessage())
      navigate("/verify", {
        state: {
          otpStatus: true
        }
      })
    }
    if (error && message) {
      errorToast(message)
      dispatch(clearError())
      dispatch(clearMessage())
    }
  }, [success, error, message, dispatch, navigate]);

  useEffect(() => {
    if (editUserInfoSuccess && editUserInfoMessage) {
      successToast(editUserInfoMessage)
      dispatch(clearMessage())
    }
    if (editUserInfoError && editUserInfoMessage) {
      errorToast(message)
      dispatch(clearError())
      dispatch(clearMessage())
    }
  }, [editUserInfoSuccess, editUserInfoError, editUserInfoMessage, dispatch]);

  return (
    <>
      <ToastContainer />
      <div className="container mx-auto p-4">
        <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%]">
          <h5 className="text-[30px] font-Poppins text-center">Welcome {userData.name} !</h5>
          <p className="text-slate-500 mt-2 text-center text-sm">
            Here is your profile info have good day
          </p>
          {/* create product form */}
          <form >
            <br />
            <div className="flex gap-5 justify-space-between">
              <div className="w-[50%]">
                <label className="pb-2">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Owner name..."
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-[50%]">
                <label className="pb-2">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Owner name..."
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="mx-auto w-52">
              <input
                // disabled={loading}
                value="Save Changes"
                type="button"
                onClick={handelUpdate}
                className="w-full bg-brandColor h-12 rounded-lg mt-5 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              />
            </div>

            <br />
            <hr />
            <br />

            <div className="flex gap-5 justify-space-between">
              <div className="w-[50%]">
                <label className="pb-2">
                  Old Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={oldPassword}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Old Password..."
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="w-[50%]">
                <label className="pb-2">
                  New Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="newPassword"
                  value={newPassword}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="New password..."
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="mx-auto w-52">
              <input
                // disabled={loading}
                value="Update Password"
                type="button"
                onClick={handelUpdatePswd}
                className="w-full bg-brandColor h-12 rounded-lg mt-5 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              />
            </div>



            <br />
            <hr />
            <br />

            <div className="w-full mt-5 relative flex justify-center items-center h-12 bg-white  rounded-xl overflow-hidden ">
              <select
                value={countryCode}
                onChange={e => setCountryCode(e.target.value)}
                className="py-2 px-1 outline-none border-none h-full"
              >
                <option value="+91" selected className="">
                  +91 IN
                </option>
              </select>

              <input
                type="number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                className="outline-none border-none  pl-2 w-full h-full"
                // required
                placeholder="Phone Number"
              />
            </div>

            <div className="mx-auto w-52">
              <input
                // disabled={loading}
                value="Update Number"
                type="button"
                onClick={handelOtpSubmit}
                className="w-full bg-brandColor h-12 rounded-lg mt-5 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              />
            </div>


          </form>
        </div>
      </div>
    </>
  );
};

export default Appointment;

import { createSlice } from "@reduxjs/toolkit"
import { getCustomerByShopper } from "../api/appointmentApis"

const initialState = {}

const getAllCustomers = createSlice({
  name: "getAllCustomers",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        allCustList: action.payload.customersList,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getAllCustomers.actions
export default getAllCustomers.reducer
export { clearError, clearMessage }

export const dispatchGetAllCustomers = () => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getCustomerByShopper()
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}

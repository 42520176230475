import React from 'react'

const Cancellations = () => {
  return (
    <div class="bg-gray-100">
        <div class="container mx-auto px-4 py-8">
            <h1 class="text-3xl font-bold mb-4">Cancellations Policy</h1>

            <p class="mb-4">
            No cancellations & Refunds are entertained
            </p>
        </div>
    </div>
  )
}

export default Cancellations
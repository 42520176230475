import { createSlice } from "@reduxjs/toolkit"
import { getShopByOwnerApi } from "../api/appointmentApis"

const initialState = {}

const getShopByOwner = createSlice({
  name: "getShopByOwner",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        shopList: action.payload.shopList,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getShopByOwner.actions
export default getShopByOwner.reducer
export { clearError, clearMessage }

export const dispatchGetShopByOwner = () => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getShopByOwnerApi()
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}

import React from 'react'
import Avatar from '@mui/material/Avatar';
import { useAppSelector } from '../../hooks/hook';
const Profile = () => {
  const { userData } = useAppSelector((state) => state.getLoggedUser);
  return (
    <div className="flex px-3 text-[1rem] py-5 flex gap-3 mt-5 font-medium overflow-x-hidden">
      <div className="avtar">
        {/* {
        userData ? <Avatar style={{ background: "salmon", fontWeight: "bold", textTransform: "capitalize" }} onClick={handleClick}>{userData.name[0].toUpperCase()}</Avatar> :
        <Avatar style={{ background: "blue" }} onClick={handleClick} />
      } */}
        <Avatar style={{ width: "50px", height: "50px", background: "#2066A9", fontWeight: "bold", textTransform: "capitalize", fontSize: "30px" }} >{userData.name[0].toUpperCase()}</Avatar>
      </div>
      <div className='flex flex-col'>
        <p className='font-semibold'>{userData?.name}</p>
        <p>{userData?.email}</p>
      </div>
    </div>
  )
}

export default Profile

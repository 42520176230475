import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { dispatchGetBookingsByCust } from '../../redux/getBookingsBycust';
import { useAppDispatch, useAppSelector } from '../../hooks/hook';
import { clearError, clearMessage } from "../../redux/getBookingsByOwner"
import { dispatchGetAllShops } from "../../redux/getAllShops"

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      center: true
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
  pagination: {
    style: {
      fontSize: '14px',
      minHeight: '32px',
    },
  },
};

const columns = [
  {
    name: 'customerName',
    selector: 'customerName',
    sortable: true,
    marginTop: '10px',
    center: true,
  },
  {
    name: 'appointmentDate',
    selector: 'appointmentDate',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'bookedDate',
    selector: 'bookedDate',
    sortable: true,
    center: true,
    marginTop: '10px',
  },

  {
    name: 'ownerName',
    selector: 'ownerName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'shopName',
    selector: 'shopName',
    sortable: true,
    center: true,
    marginTop: '10px',
  },
  {
    name: 'slot',
    selector: 'slot',
    sortable: true,
    center: true,
    marginTop: '10px',
    cell: row => `${row.slot[0].startTime} - ${row.slot[0].endTime}`,
  },
];
const AllPayments = () => {
  const { custBookingList, loading, error, message, success } = useAppSelector((state) => state.custBookingList);
  const dispatch = useAppDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    dispatch(dispatchGetBookingsByCust())
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching shop data:", error);
        setDataLoaded(true);
      });
  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(
  //     // dispatchBookAppointment({
  //     //   ownerName, shopName, slot: selectedSlot, appointmentDate: selectedDate, custName: userData.name, custId: userData._id
  //     // })
  //     dispatchGetAllShops()
  //   )
  // }, [dispatch]);
  if (loading || !dataLoaded) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!custBookingList || custBookingList.length === 0) {
    return <div className='text-center text-3xl m-10 font-[500]'>No records to display</div>;
  }

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">My Bookings</h1>
        <DataTable
          title="Booking List"
          columns={columns}
          // data={[custBookingList]}

          data={Array.isArray(custBookingList) ? custBookingList : [custBookingList]}

          customStyles={customStyles}
          pagination
        />
      </div>
    </>
  );
};

export default AllPayments;

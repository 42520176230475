import React, { useState } from 'react'
import { ToastContainer } from "react-toastify"
import { errorToast, successToast } from '../../helper/toast'
import axios from "axios";
import { server } from "../../server";
import { useNavigate } from "react-router-dom";
import { validatePhoneNumber } from '../../helper/validation';
import { useAppSelector } from "../../hooks/hook"

const AddGuestUser = () => {
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countryCode, setCountryCode] = useState("91");
    const { userData } = useAppSelector(state => state.getLoggedUser)

    const navigate = useNavigate();
    var api = userData.role==='admin' ? `${server}/api/user/admin/addGuestUser` : `${server}/api/user/shopper/addGuestUser`;
    const handleSubmit = async () => {
        try {
          if(name && phoneNumber) {
            const isValidPhoneNumber = validatePhoneNumber(phoneNumber);
            if(isValidPhoneNumber) {
              const { data } = await axios.post(
                api,
                {name, phoneNumber:`${countryCode}${phoneNumber}`, guestUser: true},
                { withCredentials: true }
              )
              console.log("data: ",  data)
              if(data.success) {
                console.log("true")
                successToast("Customer add successfully")
                if(userData.role==='admin')
                navigate('/admin/book-appointment');
                else 
                navigate('/shopper/book-appointment');
              }
            } else 
              errorToast("Invalid Phone Number 🙄");
        }
        } catch (error) {
          if(error.response.data.error)
          errorToast(error.response.data.message)
          else 
          errorToast("Error while adding guest user")
        }
    }
  return (
    <>
    <ToastContainer />
    <div className="container mx-auto p-4">
      <div className="800px:w-[50%] bg-white mt-5 mx-auto my-auto px-10 py-5 rounded-[4px] shadow w-[90%] overflow-y-scroll h-[90vh]">
        <h5 className="text-[30px] font-Poppins text-center">Add New Shop</h5>
        {/* create product form */}
        <form>
          <br />
          <div className="flex gap-5 justify-between">
            <div className="w-full">
              <label className="pb-2">
                User first name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setName(e.target.value)}
                placeholder="Give unique shop name..."
              />
            </div>
          </div>

          <div className="w-full mt-5 relative flex justify-center items-center h-12 bg-white rounded-xl overflow-hidden ">
                <select
                  value={countryCode}
                  onChange={e => setCountryCode(e.target.value)}
                  className="py-2 px-1 h-full"
                >
                  <option value="91" selected className="">
                    +91 IN
                  </option>
                </select>
                <input
                  type="number"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  // required
                  placeholder="Phone Number"
                />
              </div>

          <div className="mx-auto w-52">
            <input
              onClick={handleSubmit}
              value={"Add Customer"}
              className="w-full bg-brandColor h-12 rounded-lg mt-6 text-white hover:bg-red-400 cursor-pointer outline-none border-none"
              type='button'
            />
          </div>
        </form>
      </div>
    </div>
  </>

  )
}

export default AddGuestUser

import React, { useState, useEffect } from 'react';

const ManageStaff = ({ numStaff, initialStaffDetails, onNumStaffChange, onStaffDetailsChange }) => {
  const [staffDetails, setStaffDetails] = useState([]);

  useEffect(() => {
    if (initialStaffDetails && initialStaffDetails.length > 0) {
      setStaffDetails(initialStaffDetails);
    } else {
      setStaffDetails(new Array(numStaff).fill({}));
    }
  }, [numStaff, initialStaffDetails]);

  const handleNumStaffChange = (e) => {
    const count = parseInt(e.target.value);
    onNumStaffChange(count);
    setStaffDetails(new Array(count).fill({}));
    onStaffDetailsChange(new Array(count).fill({}));
  };

  const handleStaffDetailChange = (index, key, value) => {
    const updatedStaffDetails = [...staffDetails];
    updatedStaffDetails[index] = { ...updatedStaffDetails[index], [key]: value };
    setStaffDetails(updatedStaffDetails);
    onStaffDetailsChange(updatedStaffDetails);
  };

  const addStaff = () => {
    const newStaffDetails = [...staffDetails, {}];
    setStaffDetails(newStaffDetails);
    onNumStaffChange(newStaffDetails.length);
    onStaffDetailsChange(newStaffDetails);
  };

  return (
    <div>
      <br/>
      <div className="flex gap-5 justify-space-between">
        <div className="w-[50%]">
          <label className="pb-2">No of Staff <span className="text-red-500">*</span></label>
          <input
            type="number"
            name="numStaff"
            value={numStaff}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleNumStaffChange}
            placeholder="No of staff..."
            min={1}
          />
        </div>
        <div className="w-[50%]">
          <div className="w-52 mt-[5px]">
            <input
              type="button"
              value={"Add Staff"}
              className="w-full bg-[#837b7b] h-10 rounded-lg mt-6 text-white hover:bg-[#6c6767] cursor-pointer outline-none border-none"
              onClick={addStaff}
            />
          </div>
        </div>
      </div>
      {/* */}
      <div className="staff-columns mt-6">
        {staffDetails.map((staff, index) => (
          <div key={index} className="staff-details border-2 px-2">
            <h3>Staff {index + 1}</h3>
            <div className="flex ">
              <label>Name: </label>
              <input
                type="text"
                value={staff.name || ''}
                onChange={(e) => handleStaffDetailChange(index, 'name', e.target.value)}
                className="w-[full] ml-2 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <br />
            <div className="flex">
            <label>Availability: </label>
            <select
              value={staff.availability || ''}
              className=" ml-2 w-[16rem] border border-gray-300 h-[30px] rounded-[3px]"
              onChange={(e) => handleStaffDetailChange(index, 'availability', e.target.value)}
            >
              <option value="">Select Availability</option>
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
            </select>
            </div>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageStaff;

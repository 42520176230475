import React from "react"

const FormContainer = ({ children }) => {
  return (
    <div className="flex justify-center items-center h-screen flex-col px-1 md:p-10 w-[100%]">
      <div className="glass py-5 px-5 md:px-10 md:h-[35rem] md:w-[30rem]">
        {children}
      </div>
    </div>
  )
}

export default FormContainer

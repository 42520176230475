import React from 'react'
import  PaymentsReceipt from '../components/Subscription/PaymentsReceipt.jsx'
const Subscription = () => {
  return (
    <div className="mx-auto w-[90%]">
      <PaymentsReceipt/>
    </div>
  )
}

export default Subscription
import { createSlice } from "@reduxjs/toolkit"
import { editShopApi } from "../../api/adminApis"

const initialState = {}

export const editShopSlice = createSlice({
  name: "editShopSlice",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        shopData: action.payload.shopData
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = editShopSlice.actions
export default editShopSlice.reducer
export { clearError, clearMessage }

export const dispatchEditShop = shopData => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await editShopApi(
      // JSON.parse(appointmentData),
      shopData
    )
    dispatch(setSuccess(response))
  } catch (error) {
    dispatch(setFailed(error))
  }
}


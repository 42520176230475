import { createSlice } from "@reduxjs/toolkit"
import { getCurrentAppointmentApi } from "../../api/adminApis"

const initialState = {}

const getCurrentAppointments = createSlice({
  name: "getCurrentAppointments",
  initialState,
  reducers: {
    setLoading(state) {
      return {
        loading: true
      }
    },
    setSuccess(state, action) {
      return {
        ...state,
        success: true,
        loading: false,
        currentBooking: action.payload.appointments,
        message: action.payload.message
      }
    },
    setFailed(state, action) {
      return {
        loading: false,
        error: action.payload.error,
        message: action.payload.message
      }
    },
    clearError(state) {
      return {
        ...state,
        error: false
      }
    },
    clearMessage(state) {
      return {
        ...state,
        message: false
      }
    }
  }
})

const {
  setLoading,
  setSuccess,
  setFailed,
  clearError,
  clearMessage
} = getCurrentAppointments.actions
export default getCurrentAppointments.reducer
export { clearError, clearMessage }

export const dispatchGetCurrentAppointments = () => async dispatch => {
  try {
    dispatch(setLoading())
    const response = await getCurrentAppointmentApi()
    dispatch(setSuccess(response))
  } catch (error) {
    setFailed(dispatch(setFailed(error)))
  }
}
